export const URL_ASSET_PICK_UP = '/asset/pickup';
export const URL_ALUMNI_REGISTRATION = '/community/registration';
export const URL_ONLINE_DONATION = '/online_donation';
export const URL_CAMPUS_DISPLAY_PAGE = '/cd';
export const URL_CAMPUS_DISPLAY_SLIDE_SHOW_BY_LOCATION_PAGE = '/cd/loc/:locationId';
export const URL_FUNNEL_THANK_YOU_PAGE = '/funnel/thankYou/:pageName';

export const URL_ENEWS_VIEW_PAGE = '/enews/:id';

export const URL_POWER_BI_DISPLAY = '/powerbi/report/:reportId';
export const URL_STUDENT_REPORT_SCHOOL_BOX_ID = '/reports/student/:schoolBoxId';
