import iSynVFutureStudent from '../../types/Synergetic/iSynVFutureStudent';
import AppService, {iConfigParams} from '../AppService';
import iPaginatedResult from '../../types/iPaginatedResult';
import iVStudent from '../../types/Synergetic/Student/iVStudent';
import ISynLuYearLevel from '../../types/Synergetic/Lookup/iSynLuYearLevel';


const endPoint = '/syn/vFutureStudent'
const getAll =  (params: iConfigParams = {}, config?: iConfigParams): Promise<iPaginatedResult<iSynVFutureStudent>> => {
  return AppService.get(endPoint, params, config).then(resp => resp.data);
};

const mapFutureStudentToCurrent = (student: iSynVFutureStudent, yLevelMap: {[key: string]: ISynLuYearLevel} = {}): iVStudent => {
  const futureYearLevel = `${student.FutureYearLevel}`;
  return {
    StudentID: student.FutureID,
    StudentNameInternal: student.FutureNameInternal,
    StudentNameExternal: student.FutureNameExternal,
    StudentLegalFullName: student.FutureLegalFullName,
    StudentSurname: student.FutureSurname,
    StudentTitle: student.FutureTitle,
    StudentInitials: student.FutureInitials,
    StudentSuffix: student.FutureSuffix,
    StudentPreferred: student.FuturePreferred,
    StudentPreferredFormal: student.FuturePreferredFormal,
    StudentGiven1: student.FutureGiven1,
    StudentGiven2: student.FutureGiven2,
    StudentOccupEmail: student.FutureOccupEmail,
    StudentBirthDate: `${student.FutureBirthDate || ''}`,
    StudentGender: student.FutureGender,
    StudentMobilePhone: student.FutureMobilePhone,
    StudentDefaultMobilePhone: student.FutureDefaultMobilePhone,
    StudentOccupMobilePhone: '',
    StudentEmail: student.FutureEmail,
    StudentEducation: '',
    StudentQualifications: '',
    FileYear: student.FutureEnrolYear,
    FileSemester: 0,
    StudentActiveFlag: true,
    StudentStatus: student.FutureStatus,
    StudentStatusDescription: student.FutureStatusDescription,
    StudentStatusSynergyMeaning: student.FutureStatusSynergyMeaning,
    StudentStatusNextYear: '',
    StudentCampus: student.FutureCampus,
    StudentCampusDescription: student.FutureCampus,
    StudentCampusSort: 0,
    StudentCampusRSBNumber: '',
    StudentYearLevel: student.FutureYearLevel,
    SchoolingYearOffset: 0,
    FundingYearLevel: student.FutureYearLevel,
    StudentTypeCode: '',
    SecondaryTertiaryProgramme: null,
    StudentOverrideNextYearLevel: null,
    StudentOverrideNextStudentCampus: '',
    StudentOverrideNextBoarder: '',
    StudentYearLevelSort: futureYearLevel in yLevelMap ? yLevelMap[futureYearLevel].YearLevelSort : 0,
    StudentYearLevelDescription: futureYearLevel in yLevelMap ? yLevelMap[futureYearLevel].Description : futureYearLevel,
    StudentYearLevelCoordinator: futureYearLevel in yLevelMap ? yLevelMap[futureYearLevel].YearLevelCoordinator : '',
    StudentYearLevelTimeTableGroup: futureYearLevel in yLevelMap ? yLevelMap[futureYearLevel].TimetableGroup : '',
    StudentYearLevelIncludeInCensusFlag: futureYearLevel in yLevelMap ? yLevelMap[futureYearLevel].IncludeInCensusFlag : false,
    StudentHouse: student.FutureHouse,
    StudentHouseDescription: student.FutureHouseDescription,
    StudentHeadOfHouse: student.FutureHeadOfHouse,
    StudentHouseCaptain: student.FutureHouseCaptain,
    StudentForm: student.FutureForm,
    StudentFormDescription: '',
    StudentFormHomeRoom: '',
    StudentFormStaffName: '',
    StudentTutor: '',
    StudentTutorDescription: '',
    StudentTutorHomeRoom: '',
    StudentTutorName: '',
    StudentBoarder: student.FutureBoarder,
    StudentBoarderSynergyMeaning: '',
    StudentBoarderType: student.FutureBoarderType,
    StudentBoardingHouse: student.FutureBoardingHouse,
    StudentLockerBook: '',
    StudentLockerOther: '',
    StudentStudiesCode: '',
    StudentGovernmentStudentNumber: '',
    StudentEarlyLearningCode: '',
    VocationalEducationCode: '',
    StudentTertiaryCode: '',
    StudentTimeTableCode: '',
    StudentEntryDate: `${student.FutureEnrolDate}`,
    StudentEntryCampus: student.FutureCampus,
    StudentEntryYearLevel: student.FutureYearLevel,
    StudentLeavingDate: null,
    StudentLeavingReason: '',
    StudentLeavingDestination: '',
    StudentReturningDate: null,
    StudentEnquirySource: '',
    StudentEnquirySourceComment: '',
    StudentPreviousSchool: student.FuturePreviousSchoolCode,
    StudentTuitionVariationType: student.FutureTuitionVariationType,
    StudentDoctorName: student.FutureDoctorName,
    StudentDoctorPhone: student.FutureDoctorPhone,
    StudentDoctorPhoneAlt: student.FutureDoctorPhoneAlt,
    StudentDoctorAddress: student.FutureDoctorAddress,
    StudentDoctorSpecialisation: student.FutureDoctorSpecialisation,
    StudentDoctor2Name: student.FutureDoctor2Name,
    StudentDoctor2Phone: student.FutureDoctor2Phone,
    StudentDoctor2PhoneAlt: student.FutureDoctorPhone2Alt,
    StudentDoctor2Address: student.FutureDoctor2Address,
    StudentDoctor2Specialisation: student.FutureDoctor2Specialisation,
    StudentDentistName: student.FutureDentistName,
    StudentDentistPhone: student.FutureDentistPhone,
    StudentDentistPhoneAlt: student.FutureDentistPhoneAlt,
    StudentDentistAddress: student.FutureDentistAddress,
    StudentEmergencyName: student.FutureEmergencyName,
    StudentEmergencyAddress: student.FutureEmergencyAddress,
    StudentEmergencyPhone: student.FutureEmergencyPhone,
    StudentEmergencyPhoneAlt: student.FutureEmergencyPhoneAlt,
    StudentEmergencyRelat: student.FutureEmergencyRelat,
    StudentEmergency2Name: student.FutureEmergency2Name,
    StudentEmergency2Address: student.FutureEmergency2Address,
    StudentEmergency2Phone: student.FutureEmergency2Phone,
    StudentEmergency2PhoneAlt: student.FutureEmergency2PhoneAlt,
    StudentEmergency2Relat: student.FutureEmergency2Relat,
    StudentEmergency3Name: student.FutureEmergency3Name,
    StudentEmergency3Address: student.FutureEmergency3Address,
    StudentEmergency3Phone: student.FutureEmergency3Phone,
    StudentEmergency3PhoneAlt: student.FutureEmergency3PhoneAlt,
    StudentEmergency3Relat: student.FutureEmergency3Relat,
    StudentLastLessonRcvdDate: null,
    StudentsVisaType: student.FutureVisaType,
    StudentVisaIssuedDate: `${student.FutureVisaIssuedDate}`,
    StudentsVisaExpiryDate: `${student.FutureVisaExpiryDate}`,
    StudentVisaNumber: student.FutureVisaNumber,
    StudentPassportCountryCode: student.FuturePassportCountryCode,
    StudentPassportIssuedDate: null,
    StudentPassportExpiryDate: `${student.FuturePassportExpiryDate}`,
    StudentsPassportNo: student.FuturePassportNo,
    StudentsComment: student.FutureComment,
    ExternalStudentFlag: student.FutureExternalStudentFlag,
    EnglishSecondFlag: student.FutureEnglishSecondFlag,
    DisabilityFlag: student.FutureDisabilityFlag,
    IntegrationFlag: false,
    FullFeeFlag: student.FutureFullFeeFlag,
    EMAFlag: false,
    IntendsTertiaryFlag: student.FutureIntendsTertiaryFlag,
    CurrentSemesterOnlyFlag: false,
    StudentFamilyPosition: 0,
    StudentParishCode: student.FutureStudentParishCode,
    StudentParishDescription: student.FutureStudentParishDescript,
    StudentReligionCode: student.ReligionCode,
    StudentReligionDescription: student.ReligionDescription,
    StudentPreviousSchoolCode: student.FuturePreviousSchoolCode,
    StudentPreviousSchoolDescription: student.FuturePreviousSchoolDescription,
    StudentPreviousSchoolRSBNumber: student.FuturePreviousSchoolRSBNumber,
    StudentPreviousSchoolSuburb: '',
    IndigenousFlag: student.FutureIndigenousFlag,
    StudentIBFlag: student.FutureIBFlag,
    StudentApplicationDate: `${student.FutureApplicationDate}`,
    StudentMedicalAlertFlag: student.FutureMedicalAlertFlag,
    StudentSpecialNeedsFlag: student.FutureSpecialNeedsFlag,
    StudentStudyAtHomeFlag: student.FutureStudyAtHomeFlag,
    StudentTSIFlag: student.FutureTSIFlag,
    StudentESLProficiencyLevel: student.FutureESLProficiencyLevel,
    StudentESLListeningLevelCode: student.FutureESLListeningLevelCode,
    StudentESLSpeakingLevelCode: student.FutureESLSpeakingLevelCode,
    StudentESLReadingLevelCode: student.FutureESLReadingLevelCode,
    StudentESLWritingLevelCode: student.FutureESLWritingLevelCode,
    LegalParentsSeparatedFlag: false,
    LegalParentsSeparatedReason: '',
    LegalCourtOrderType: '',
    LegalCourtOrderDate: null,
    LegalCourtOrderDetails: '',
    LegalPhotoWebFlag: student.LegalPhotoWebFlag,
    LegalPhotoPromFlag: student.LegalPhotoPromFlag,
    LegalPhotoPublicationFlag: student.LegalPhotoPublicationFlag,
    LegalPrivacyPolicyAgreedFlag: false,
    StudentBarcode: student.FutureBarcode,
    StudentMedicalAlert: student.FutureMedicalAlert,
    StudentSchoolFTE: null,
    StudentInitialSchoolingYear: null,
    StudentInitialSchoolingDate: null,
    StudentNonResidentFlag: student.FutureNonResidentFlag,
    DirectoryIncludeFlag: false,
    DirectoryProvideFlag: false,
    AddressID: null,
    StudentBirthCertificateOnFileFlag: student.FutureBirthCertificateFlag,
    StudentCountryOfBirthCode: '',
    StudentCountryOfBirthCodeRaw: '',
    StudentCountryOfBirthDescription: '',
    StudentHomeLanguageCode: '',
    StudentHomeLanguageDescription: '',
    ActualSemester: false,
    StudentAltCode: '',
    ImportantCommentsFlag: student.FutureCommentWarningFlag,
    StudentLeavingDestinationDesc: '',
    ID: student.FutureID,
    DebtorID: null,
    MCEETYAFormReceivedDate: `${student.MCEETYAFormReceivedDate || ''}`.trim() === '' ? null : `${student.MCEETYAFormReceivedDate}`,
    StudentNationalityCode: student.FutureNationalityCode,
    StudentNationalityDescription: '',
    StudentNationality2Code: student.FutureNationality2Code,
    StudentNationality2Description: '',
    StudentIntendedTertiaryCourseCode: '',
    StudentSubSchool: student.FutureSubSchool,
    AustudyFlag: student.FutureAustudyFlag,
    StudentEmergencyRelatDesc: student.FutureEmergencyRelatDescription,
    StudentEmergency2RelatDesc: student.FutureEmergency2RelatDescription,
    StudentEmergency3RelatDesc: student.FutureEmergency3RelatDescription,
    AllowAutoTuitionFlag: student.FutureAllowAutoChargesFlag,
    SpecialBillingType: student.FutureSpecialBillingType,
    TuitionNoSibFlag: student.FutureTuitionNoSibFlag,
    LearningPathway: student.LearningPathway,
    GenderSynergyMeaning: student.GenderSynergyMeaning,
    profileUrl: '',
    isFuture: true,
  }
}

const SynVFutureStudentService = {
  getAll,
  mapFutureStudentToCurrent
};

export default SynVFutureStudentService;
