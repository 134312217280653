import OnlineDonation from './OnlineDonation';
import React from 'react';
import {Container} from 'react-bootstrap';

const OnlineDonationPage = () => {
  return (
    <>
      <Container>
        <h3>Donation Online</h3>
        <OnlineDonation />
      </Container>
    </>
  )
}

export default OnlineDonationPage;
