
export const SYN_DEBTOR_STATEMENT_GROUP_MONTHLY = 'MONTHLY';

type iSynVDebtor = {
  DebtorID: number;
  DebtorStudentID: number;
  DebtorSubLedgerCode: string;
  DebtorSubLedgerCodeDisplay: string;
  DebtorStatementGroup: string;
  DebtorStatus: string;
  BPAYReference: string;
  DebtorNameInternal: string;
  DebtorNameExternal: string;
  DebtorLegalFullName: string;
  DebtorSurname: string;
  DebtorTitle: string;
  DebtorInitials: string;
  DebtorSuffix: string;
  DebtorPreferred: string;
  DebtorPreferredFormal: string;
  DebtorGiven1: string;
  DebtorGiven2: string;
  DebtorGender: string;
  DebtorCreatedDate: Date;
  DebtorCreatedBy: string;
  DebtorModifiedDate: Date;
  DebtorModifiedBy: string;
  DebtorAllowReceiptPrintFlag: boolean;
  DebtorAllowReminderFlag: boolean;
  DebtorAllowInterestFlag: boolean;
  DebtorAllowDeleteFlag: boolean;
  DebtorReferenceType: string;
  DebtorReference: string;
  DebtorPrepaid: number;
  DebtorCurrentBalance: number;
  DebtorNotifiedBalance: number;
  DebtorLastPaymentDate: Date;
  DebtorLastPaymentAmount: number;
  DebtorArrangementActiveFlag: boolean;
  DebtorLastOverdueLetterCode: string;
  DebtorLastOverdueLetterDate: Date;
  DebtorLastOverdueLetterAmount: number;
  DebtorLastOverdueLetterRefDate: Date;
  DebtorLastOverdueLetterDaysBetween: number;
  DebtorMailName: string;
  DebtorPrimaryOnlyFlag: boolean;
  DebtorMailSalutation: string;
  DebtorAddressComma: string;
  DebtorAddressFull: string;
  DebtorAddressDPID: string;
  DebtorAddressDPIDBarcode: string;
  DebtorAddressSortPlanNumber: string;
  DebtorAddressPAFVersion: string;
  DebtorSuburb: string;
  DebtorPostcode: string;
  DebtorCountryCode: string;
  DebtorMailAddressCode: string;
  DebtorOccupCode: string;
  DebtorOccupDesc: string;
  DebtorOccupCompany: string;
  DebtorOccupIndustryCode: string;
  DebtorOccupPositionCode: string;
  DebtorOccupPhone: string;
  DebtorOccupFax: string;
  DebtorOccupMobilePhone: string;
  DebtorOccupEmail: string;
  DebtorHomePhone: string;
  DebtorHomeFax: string;
  DebtorMobilePhone: string;
  DebtorHomeEmail: string;
  DebtorSpouseNameInternal: string;
  DebtorSpouseNameExternal: string;
  DebtorSpouseLegalFullName: string;
  DebtorSpouseGiven1: string;
  DebtorSpouseGiven2: string;
  DebtorSpousePreferred: string;
  DebtorSpousePreferredFormal: string;
  DebtorSpouseSurname: string;
  DebtorSpouseMobilePhone: string;
  DebtorSpouseOccupPhone: string;
  DebtorSpouseOccupMobilePhone: string;
  DebtorCommentWarningFlag: boolean;
  DebtorComment: string;
  DebtorCommentWarningChargesFlag: boolean;
  DebtorCommentWarningReceiptsFlag: boolean;
  DebtorPaymentAutoDeductAmount: number;
  DebtorPaymentAutoDeductFrequency: string;
  DebtorPaymentAutoDeductNextDate: Date | null;
  DebtorPaymentAutoDeductHoldFlag: boolean;
  DebtorPaymentAutoDeductMethod: string;
  DebtorPaymentAutoDeductStopDate: Date | null;
  DebtorEziDebitCreatedDate: Date | null;
  DebtorEziDebitCreatedFlag: boolean;
  DebtorEziDebitCustomerRef: string | null;
  DebtorAutoDebitAuthorisedDate: Date | null;
  DebtorAddress1Silent: string;
  DebtorAddress2Silent: string;
  DebtorSuburbSilent: string;
  DebtorPostCodeSilent: string;
  DebtorStateSilent: string;
  DebtorMobilePhoneSilent: string;
  DebtorIDFull: string;
  DebtorAllowSales: string;
  DebtorAllowSalesFlag: boolean;
  DebtorNotifyMethod: string;
}

export default iSynVDebtor;
