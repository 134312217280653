import * as _ from 'lodash';

// "Please enter a number of 1, 2, 3 or 9 for the sex (gender) of the student:
// 1 = Male;
// 2 = Female;
// 3 = Another term/Non-binary (including use a different term)
// 9 = Where not stated or adequately described."

import {iVPastAndCurrentStudent} from '../../../../types/Synergetic/Student/iVStudent';
import iAcaraData from './iAcaraData';

const translateGender = (student: iVPastAndCurrentStudent): string => {
  switch (`${student.StudentGender}`.trim().toUpperCase()) {
    case 'M': {
      return '1';
    }
    case 'F': {
      return '2';
    }
    default: {
      return '9';
    }
  }
}

// "Please enter a number of 1, 2, 3, 4 or 9 for the Aboriginal and/or Torres Strait Islander status of the student:
// 1 = Aboriginal but not Torres Strait Islander Origin;
// 2 = Torres Strait Islander but not Aboriginal Origin;
// 3 = Both Aboriginal and Torres Strait Islander Origin;
// 4 = Neither Aboriginal nor Torres Strait Islander Origin;
// 9 = Not stated/Unknown."
const translateATSIStatus = (student: iVPastAndCurrentStudent): string => {
  if (student.IndigenousFlag === true && student.StudentTSIFlag === true) {
    return '3';
  }

  if (student.IndigenousFlag !== true &&  student.StudentTSIFlag === true) {
    return '2';
  }

  if (student.IndigenousFlag === true &&  student.StudentTSIFlag !== true) {
    return '3';
  }

  if (student.IndigenousFlag !== true &&  student.StudentTSIFlag !== true) {
    return '4';
  }

  return '9'
}

// "Please enter a number of 0, 1, 2, 3 or 4 for father/parent 2/guardian 2 highest level of school completed:
// 4 = Year 12 or equivalent;
// 3 = Year 11 or equivalent;
// 2 = Year 10 or equivalent;
// 1 = Year 9 or equivalent or below;
// 0 = Not stated/Unknown."
const translateHighestSchoolEdu = (yearLevel: string) => {
  const string = `${yearLevel}`.trim();
  if (string === '') {
    return '0'
  }
  if (string >= '12') {
    return '4'
  }
  if (string >= '11') {
    return '3'
  }
  if (string >= '10') {
    return '2'
  }
  if (string < '10') {
    return '1'
  }
  return string;
}

const validateHighestSchoolEdu = (yearLevel: string) => {
  return ['4', '3', '2', '1', '0'].indexOf(`${yearLevel}`.trim()) >= 0
}

const acaraLanguages = [
  {code: `0000`, name: `All`},
  {code: `0000`, name: `Asian`},
  {code: `0000`, name: `Belgian`},
  {code: `0000`, name: `Caledonian`},
  {code: `0000`, name: `Chadonese`},
  {code: `0000`, name: `Child`},
  {code: `0000`, name: `Cibian`},
  {code: `0000`, name: `Dialect`},
  {code: `0000`, name: `Double Dutch`},
  {code: `0000`, name: `European`},
  {code: `0000`, name: `Gibberish`},
  {code: `0000`, name: `Inadequately Described`},
  {code: `0000`, name: `Islamik`},
  {code: `0000`, name: `Jedi`},
  {code: `0000`, name: `Jibberish`},
  {code: `0000`, name: `Klingon`},
  {code: `0000`, name: `Li`},
  {code: `0000`, name: `Lingo`},
  {code: `0000`, name: `Maco`},
  {code: `0000`, name: `Many`},
  {code: `0000`, name: `Mendi`},
  {code: `0000`, name: `Multilingual`},
  {code: `0000`, name: `Nia`},
  {code: `0000`, name: `Pig Latin`},
  {code: `0000`, name: `Several`},
  {code: `0000`, name: `Singaporean`},
  {code: `0000`, name: `Slang`},
  {code: `0000`, name: `South African`},
  {code: `0000`, name: `Tongues`},
  {code: `0000`, name: `Unknown tongue`},
  {code: `0001`, name: `Aphasic`},
  {code: `0001`, name: `Autistic`},
  {code: `0001`, name: `Babble`},
  {code: `0001`, name: `Baby`},
  {code: `0001`, name: `Baby language`},
  {code: `0001`, name: `Baby talk`},
  {code: `0001`, name: `Can't speak`},
  {code: `0001`, name: `Deaf`},
  {code: `0001`, name: `Does not speak yet`},
  {code: `0001`, name: `Doesnt talk`},
  {code: `0001`, name: `Infant`},
  {code: `0001`, name: `Mute`},
  {code: `0001`, name: `Newborn`},
  {code: `0001`, name: `Newborn baby`},
  {code: `0001`, name: `Nil`},
  {code: `0001`, name: `No`},
  {code: `0001`, name: `No language`},
  {code: `0001`, name: `No speech`},
  {code: `0001`, name: `Non speaking`},
  {code: `0001`, name: `Non verbal`},
  {code: `0001`, name: `Non Verbal, so described`},
  {code: `0001`, name: `None`},
  {code: `0001`, name: `Nonverbal communication`},
  {code: `0001`, name: `Not able to speak`},
  {code: `0001`, name: `Nothing`},
  {code: `0001`, name: `Nothing yet`},
  {code: `0001`, name: `Speech impediment`},
  {code: `0001`, name: `Too small`},
  {code: `0001`, name: `Too young`},
  {code: `0001`, name: `Too young to speak`},
  {code: `0001`, name: `Yet to speak`},
  {code: `0002`, name: `Not Stated`},
  {code: `0003`, name: `Swiss`},
  {code: `0003`, name: `Swiss, so described`},
  {code: `0004`, name: `Cyprian`},
  {code: `0004`, name: `Cypriot`},
  {code: `0004`, name: `Cypriot, so decribed`},
  {code: `0004`, name: `Cyprus`},
  {code: `0005`, name: `Creole`},
  {code: `0005`, name: `Creole, nfd`},
  {code: `0005`, name: `Eastern Creole`},
  {code: `0006`, name: `Creole French`},
  {code: `0006`, name: `French Creole`},
  {code: `0006`, name: `French Creole, nfd`},
  {code: `0007`, name: `Creole Spanish`},
  {code: `0007`, name: `Spanish Creole`},
  {code: `0007`, name: `Spanish Creole, nfd`},
  {code: `0008`, name: `Creole Portuguese`},
  {code: `0008`, name: `Portuguese Creole`},
  {code: `0008`, name: `Portuguese Creole, nfd`},
  {code: `0009`, name: `Pidgeon`},
  {code: `0009`, name: `Pidgin, nfd`},
  {code: `0009`, name: `Pidgin`},
  {code: `0009`, name: `Pigeon`},
  {code: `0009`, name: `Pigin`},
  {code: `1000`, name: `Northern European`},
  {code: `1000`, name: `Northern European Languages, nfd`},
  {code: `1100`, name: `Celtic`},
  {code: `1100`, name: `Celtic, nfd`},
  {code: `1101`, name: `Doric`},
  {code: `1101`, name: `Gaelic`},
  {code: `1101`, name: `Gaelic (Scotland)`},
  {code: `1101`, name: `Gaelic Scotland`},
  {code: `1101`, name: `Gaelic Scottish`},
  {code: `1101`, name: `Gaidhlig`},
  {code: `1101`, name: `Galic`},
  {code: `1101`, name: `Scottish Gaelic`},
  {code: `1102`, name: `Erse`},
  {code: `1102`, name: `Gaeilge`},
  {code: `1102`, name: `Gaelic Irish`},
  {code: `1102`, name: `Irish`},
  {code: `1102`, name: `Irish gaelic`},
  {code: `1103`, name: `Cymraeg`},
  {code: `1103`, name: `Cymric`},
  {code: `1103`, name: `Welsh`},
  {code: `1199`, name: `Breton`},
  {code: `1199`, name: `Celtic, nec`},
  {code: `1199`, name: `Cornish`},
  {code: `1199`, name: `Manx`},
  {code: `1201`, name: `American`},
  {code: `1201`, name: `Aussie`},
  {code: `1201`, name: `Aust`},
  {code: `1201`, name: `Aust slang`},
  {code: `1201`, name: `Australian`},
  {code: `1201`, name: `Australian slang`},
  {code: `1201`, name: `Bad English`},
  {code: `1201`, name: `Canadian`},
  {code: `1201`, name: `Cockney`},
  {code: `1201`, name: `England`},
  {code: `1201`, name: `English`},
  {code: `1201`, name: `Jersey Channel Island`},
  {code: `1201`, name: `Kiwi`},
  {code: `1201`, name: `New Zealand`},
  {code: `1201`, name: `Nth Ireland`},
  {code: `1201`, name: `NZ`},
  {code: `1201`, name: `Ozzi`},
  {code: `1201`, name: `Pikey`},
  {code: `1201`, name: `Scotish`},
  {code: `1201`, name: `Scotland`},
  {code: `1201`, name: `Scottish`},
  {code: `1201`, name: `Strine`},
  {code: `1300`, name: `German and Related Languages, nfd`},
  {code: `1301`, name: `Austrian`},
  {code: `1301`, name: `Bavaria`},
  {code: `1301`, name: `Deutsch`},
  {code: `1301`, name: `Franco German`},
  {code: `1301`, name: `German`},
  {code: `1301`, name: `Germany`},
  {code: `1301`, name: `Schweizerdeutsch`},
  {code: `1301`, name: `Swiss German`},
  {code: `1302`, name: `Letzeburgesch`},
  {code: `1302`, name: `Letzeburgish`},
  {code: `1302`, name: `Luxembourgish`},
  {code: `1303`, name: `Idisch`},
  {code: `1303`, name: `Jewish`},
  {code: `1303`, name: `Judaeo German`},
  {code: `1303`, name: `Yiddisch`},
  {code: `1303`, name: `Yiddish`},
  {code: `1400`, name: `Dutch and Related Languages, nfd`},
  {code: `1401`, name: `Dutch`},
  {code: `1401`, name: `Flemish`},
  {code: `1401`, name: `Flemish French`},
  {code: `1401`, name: `Hollands`},
  {code: `1401`, name: `Nederlands`},
  {code: `1401`, name: `Netherlandic`},
  {code: `1401`, name: `Netherlands`},
  {code: `1401`, name: `Vlaams`},
  {code: `1402`, name: `Friesian`},
  {code: `1402`, name: `Frisian`},
  {code: `1403`, name: `Africanse`},
  {code: `1403`, name: `Afrikaans`},
  {code: `1403`, name: `Afrikaner`},
  {code: `1403`, name: `Afrikanss`},
  {code: `1403`, name: `Cape Dutch`},
  {code: `1500`, name: `Scandinavian`},
  {code: `1500`, name: `Scandinavian, nfd`},
  {code: `1501`, name: `Danish`},
  {code: `1501`, name: `Dansk`},
  {code: `1502`, name: `Icelandic`},
  {code: `1503`, name: `Bokmal`},
  {code: `1503`, name: `Norsk`},
  {code: `1503`, name: `Norwegian`},
  {code: `1503`, name: `Norwegon`},
  {code: `1503`, name: `Norweigian`},
  {code: `1503`, name: `Norweigon`},
  {code: `1504`, name: `Svensk`},
  {code: `1504`, name: `Swedish`},
  {code: `1599`, name: `Faeroese`},
  {code: `1599`, name: `Faroese`},
  {code: `1599`, name: `Scandinavian, nec`},
  {code: `1600`, name: `Finnic`},
  {code: `1600`, name: `Finnish and Related Languages, nfd`},
  {code: `1601`, name: `Estonian`},
  {code: `1602`, name: `Fin`},
  {code: `1602`, name: `Finland`},
  {code: `1602`, name: `Finn`},
  {code: `1602`, name: `Finnis`},
  {code: `1602`, name: `Finnish`},
  {code: `1602`, name: `Suomi`},
  {code: `1699`, name: `Finnish and Related Languages, nec`},
  {code: `1699`, name: `Karelian`},
  {code: `1699`, name: `Lapp`},
  {code: `1699`, name: `Ludic`},
  {code: `1699`, name: `Mordovian`},
  {code: `1699`, name: `Saami`},
  {code: `2000`, name: `European south`},
  {code: `2000`, name: `Southern European`},
  {code: `2000`, name: `Southern European Languages, nfd`},
  {code: `2101`, name: `Acadian`},
  {code: `2101`, name: `Canadian French`},
  {code: `2101`, name: `Francais`},
  {code: `2101`, name: `France`},
  {code: `2101`, name: `French`},
  {code: `2101`, name: `French Canadian`},
  {code: `2101`, name: `French Swiss`},
  {code: `2101`, name: `Occitan`},
  {code: `2101`, name: `Suisse`},
  {code: `2101`, name: `Swiss French`},
  {code: `2101`, name: `Walloon`},
  {code: `2201`, name: `Aegean`},
  {code: `2201`, name: `Greek`},
  {code: `2201`, name: `Greek Cypriot`},
  {code: `2300`, name: `Hispanic`},
  {code: `2300`, name: `Iberian romance`},
  {code: `2300`, name: `Iberian romance, nfd`},
  {code: `2300`, name: `South American`},
  {code: `2301`, name: `Catala`},
  {code: `2301`, name: `Catalan`},
  {code: `2301`, name: `Catalonian`},
  {code: `2302`, name: `Brasilian`},
  {code: `2302`, name: `Brazilian`},
  {code: `2302`, name: `Portages`},
  {code: `2302`, name: `Portugal`},
  {code: `2302`, name: `Portugese`},
  {code: `2302`, name: `Portugues`},
  {code: `2302`, name: `Portuguese`},
  {code: `2303`, name: `Argentina`},
  {code: `2303`, name: `Bolivia`},
  {code: `2303`, name: `Castellano`},
  {code: `2303`, name: `Castilian`},
  {code: `2303`, name: `Chili`},
  {code: `2303`, name: `Chilian`},
  {code: `2303`, name: `El Salvadorian`},
  {code: `2303`, name: `Espagnol`},
  {code: `2303`, name: `Espanish`},
  {code: `2303`, name: `Espanol`},
  {code: `2303`, name: `Guatamalan`},
  {code: `2303`, name: `Mexican`},
  {code: `2303`, name: `Peruvian`},
  {code: `2303`, name: `Salvadorian`},
  {code: `2303`, name: `Spain`},
  {code: `2303`, name: `Spanish`},
  {code: `2303`, name: `Uruguayan`},
  {code: `2399`, name: `Galician`},
  {code: `2399`, name: `Iberian Romance, nec`},
  {code: `2399`, name: `Judaeo Spanish`},
  {code: `2399`, name: `Ladino`},
  {code: `2401`, name: `Calabrian`},
  {code: `2401`, name: `Catanese`},
  {code: `2401`, name: `Italian`},
  {code: `2401`, name: `Italiano`},
  {code: `2401`, name: `Italy`},
  {code: `2401`, name: `Roman`},
  {code: `2401`, name: `Sardinian`},
  {code: `2401`, name: `Scilian`},
  {code: `2401`, name: `Sicilian`},
  {code: `2401`, name: `Triestine`},
  {code: `2501`, name: `Malta`},
  {code: `2501`, name: `Maltease`},
  {code: `2501`, name: `Maltese`},
  {code: `2501`, name: `Malthese`},
  {code: `2501`, name: `Malti`},
  {code: `2900`, name: `Other Southern European Languages, nfd`},
  {code: `2901`, name: `Basque`},
  {code: `2902`, name: `Latin`},
  {code: `2999`, name: `Friulian`},
  {code: `2999`, name: `Istrian`},
  {code: `2999`, name: `Ladin`},
  {code: `2999`, name: `Other Southern European Languages, nec`},
  {code: `2999`, name: `Rhaetian`},
  {code: `2999`, name: `Romansch`},
  {code: `2999`, name: `Romansh`},
  {code: `2999`, name: `Romantsch`},
  {code: `3000`, name: `Eastern European`},
  {code: `3000`, name: `Eastern European Languages, nfd`},
  {code: `3000`, name: `Slav`},
  {code: `3000`, name: `Slavic`},
  {code: `3000`, name: `Slavonic`},
  {code: `3000`, name: `Slov`},
  {code: `3100`, name: `Baltic`},
  {code: `3100`, name: `Baltic, nfd`},
  {code: `3101`, name: `Lativan`},
  {code: `3101`, name: `Latvian`},
  {code: `3101`, name: `Lettish`},
  {code: `3102`, name: `Lithuanian`},
  {code: `3301`, name: `Hangery`},
  {code: `3301`, name: `Hungari`},
  {code: `3301`, name: `Hungarian`},
  {code: `3301`, name: `Hungary`},
  {code: `3301`, name: `Magyar`},
  {code: `3400`, name: `East Slavic`},
  {code: `3400`, name: `East Slavic, nfd`},
  {code: `3401`, name: `Belarusan`},
  {code: `3401`, name: `Bielorussian`},
  {code: `3401`, name: `Belarusian`},
  {code: `3401`, name: `Belorus`},
  {code: `3401`, name: `Belorussian`},
  {code: `3401`, name: `Byelorussian`},
  {code: `3401`, name: `Russian White`},
  {code: `3401`, name: `White Russian`},
  {code: `3402`, name: `Great Russian`},
  {code: `3402`, name: `Rashan`},
  {code: `3402`, name: `Rusian`},
  {code: `3402`, name: `Russe`},
  {code: `3402`, name: `Russia`},
  {code: `3402`, name: `Russian`},
  {code: `3403`, name: `Ruthenian`},
  {code: `3403`, name: `Ukrahian`},
  {code: `3403`, name: `Ukraine`},
  {code: `3403`, name: `Ukrainian`},
  {code: `3403`, name: `Ukranian`},
  {code: `3403`, name: `Ukranian Rusian`},
  {code: `3500`, name: `Slavic south`},
  {code: `3500`, name: `South Slavic`},
  {code: `3500`, name: `South Slavic, nfd`},
  {code: `3501`, name: `Bosanski`},
  {code: `3501`, name: `Boshiah`},
  {code: `3501`, name: `Bosnia`},
  {code: `3501`, name: `Bosnian`},
  {code: `3501`, name: `Bosnijen`},
  {code: `3502`, name: `Bulgarian`},
  {code: `3503`, name: `Coation`},
  {code: `3503`, name: `Croashian`},
  {code: `3503`, name: `Croat`},
  {code: `3503`, name: `Croataen`},
  {code: `3503`, name: `Croaten`},
  {code: `3503`, name: `Croatian`},
  {code: `3503`, name: `Croation`},
  {code: `3503`, name: `Crouation`},
  {code: `3503`, name: `Dalmation`},
  {code: `3503`, name: `Hrvatska`},
  {code: `3503`, name: `Hrvatski`},
  {code: `3504`, name: `Macadian`},
  {code: `3504`, name: `Macadonian`},
  {code: `3504`, name: `Macedon`},
  {code: `3504`, name: `Macedonan`},
  {code: `3504`, name: `Macedonia`},
  {code: `3504`, name: `Macedonian`},
  {code: `3504`, name: `Macedonijan`},
  {code: `3504`, name: `Macodian`},
  {code: `3504`, name: `Makedoneki`},
  {code: `3504`, name: `Makedonia`},
  {code: `3504`, name: `Makedonian`},
  {code: `3504`, name: `Makedonski`},
  {code: `3504`, name: `Massadona`},
  {code: `3505`, name: `Montenegrin`},
  {code: `3505`, name: `Serb`},
  {code: `3505`, name: `Serbia`},
  {code: `3505`, name: `Serbian`},
  {code: `3505`, name: `Serbien`},
  {code: `3505`, name: `Serbo`},
  {code: `3505`, name: `Serbs`},
  {code: `3505`, name: `Srbian`},
  {code: `3505`, name: `Srbijan`},
  {code: `3505`, name: `Srpski`},
  {code: `3506`, name: `Slovanian`},
  {code: `3506`, name: `Slovene`},
  {code: `3506`, name: `Slovenian`},
  {code: `3506`, name: `Slovenijen`},
  {code: `3507`, name: `Croat Serbian`},
  {code: `3507`, name: `Croatian Serbian`},
  {code: `3507`, name: `Croato Serbian`},
  {code: `3507`, name: `Jugoslav`},
  {code: `3507`, name: `Jugoslavian`},
  {code: `3507`, name: `Jugoslavina`},
  {code: `3507`, name: `Serb Croat`},
  {code: `3507`, name: `Serb Croatian`},
  {code: `3507`, name: `Serbian Croat`},
  {code: `3507`, name: `Serbian Croatian`},
  {code: `3507`, name: `Serbo Croat`},
  {code: `3507`, name: `Serbo Croatian`},
  {code: `3507`, name: `Serbo Croato`},
  {code: `3507`, name: `Serbo-Croatian/Yugoslavian, so described`},
  {code: `3507`, name: `Ygoslave`},
  {code: `3507`, name: `Yougslavia`},
  {code: `3507`, name: `Yug`},
  {code: `3507`, name: `Yugo`},
  {code: `3507`, name: `Yugoslav`},
  {code: `3507`, name: `Yugoslavian`},
  {code: `3600`, name: `West Slavic`},
  {code: `3600`, name: `West Slavic, nfd`},
  {code: `3601`, name: `Ceck`},
  {code: `3601`, name: `Chek`},
  {code: `3601`, name: `Chez`},
  {code: `3601`, name: `Czech`},
  {code: `3601`, name: `Czeck`},
  {code: `3602`, name: `Poland`},
  {code: `3602`, name: `Polish`},
  {code: `3602`, name: `Polski`},
  {code: `3603`, name: `Slovak`},
  {code: `3603`, name: `Slovakian`},
  {code: `3603`, name: `Slovensky`},
  {code: `3604`, name: `Czechoslovakian`},
  {code: `3604`, name: `Czechoslovakian, so described`},
  {code: `3900`, name: `Other Eastern European Languages, nfd`},
  {code: `3901`, name: `Albaian`},
  {code: `3901`, name: `Albania`},
  {code: `3901`, name: `Albanian`},
  {code: `3901`, name: `Albanien`},
  {code: `3903`, name: `Aromunian`},
  {code: `3903`, name: `Aromunian (Macedo-Romanian)`},
  {code: `3903`, name: `Vlach`},
  {code: `3904`, name: `Moldavian`},
  {code: `3904`, name: `Moldovan`},
  {code: `3904`, name: `Romanian`},
  {code: `3904`, name: `Roumanian`},
  {code: `3904`, name: `Rumanian`},
  {code: `3905`, name: `Gypsy`},
  {code: `3905`, name: `Romanes`},
  {code: `3905`, name: `Romany`},
  {code: `3999`, name: `Lusatian`},
  {code: `3999`, name: `Other Eastern European Languages, nec`},
  {code: `3999`, name: `Sorbian`},
  {code: `3999`, name: `Wendish`},
  {code: `4000`, name: `Southwest and Central Asian Languages, nfd`},
  {code: `4000`, name: `Southwest Asian`},
  {code: `4100`, name: `Afghan`},
  {code: `4100`, name: `Afghans`},
  {code: `4100`, name: `Afghany`},
  {code: `4100`, name: `Iran`},
  {code: `4100`, name: `Iranic`},
  {code: `4100`, name: `Iranic, nfd`},
  {code: `4101`, name: `Central Kurdish`},
  {code: `4101`, name: `Faili`},
  {code: `4101`, name: `Feyli`},
  {code: `4101`, name: `Kirmanji`},
  {code: `4101`, name: `Kurdi`},
  {code: `4101`, name: `Kurdish`},
  {code: `4101`, name: `Kurdish Feyli`},
  {code: `4101`, name: `Kurdish Kurmanji`},
  {code: `4101`, name: `Kurdish Sorani`},
  {code: `4101`, name: `Kurdish Soranii`},
  {code: `4101`, name: `Kurmanji`},
  {code: `4101`, name: `Northern Kurdish`},
  {code: `4101`, name: `Soranii`},
  {code: `4101`, name: `Southern Kurdish`},
  {code: `4102`, name: `Pashto`},
  {code: `4102`, name: `Pashtu`},
  {code: `4102`, name: `Pushto`},
  {code: `4102`, name: `Pushtu`},
  {code: `4104`, name: `Balochi`},
  {code: `4104`, name: `Baluchi`},
  {code: `4105`, name: `Darre`},
  {code: `4105`, name: `Afghan Farsi`},
  {code: `4105`, name: `Daree`},
  {code: `4105`, name: `Darei`},
  {code: `4105`, name: `Darey Afghani`},
  {code: `4105`, name: `Dari`},
  {code: `4105`, name: `Dariy`},
  {code: `4105`, name: `Darri`},
  {code: `4105`, name: `Dary`},
  {code: `4105`, name: `Deria`},
  {code: `4105`, name: `Duri`},
  {code: `4105`, name: `Eastern Farsi`},
  {code: `4105`, name: `Kabuli`},
  {code: `4105`, name: `Panjsheri`},
  {code: `4105`, name: `Parsiwan`},
  {code: `4105`, name: `Persian Dari`},
  {code: `4105`, name: `Tadjik`},
  {code: `4105`, name: `Tajik`},
  {code: `4106`, name: `Farsi`},
  {code: `4106`, name: `Farsi Persian`},
  {code: `4106`, name: `Iranian`},
  {code: `4106`, name: `Parisan`},
  {code: `4106`, name: `Parsi`},
  {code: `4106`, name: `Parsian`},
  {code: `4106`, name: `Perion`},
  {code: `4106`, name: `Persian (excluding Dari)`},
  {code: `4106`, name: `Persian Farsi`},
  {code: `4106`, name: `Pharsi`},
  {code: `4106`, name: `Western Farsi`},
  {code: `4107`, name: `Azargi`},
  {code: `4107`, name: `Hazara`},
  {code: `4107`, name: `Hazaraghi`},
  {code: `4107`, name: `Hazaragi`},
  {code: `4107`, name: `Hazargi`},
  {code: `4107`, name: `Hazari`},
  {code: `4107`, name: `Hezareh`},
  {code: `4199`, name: `Iranic, nec`},
  {code: `4199`, name: `Ossetic`},
  {code: `4200`, name: `Middle Eastern`},
  {code: `4200`, name: `Middle Eastern Semitic Languages, nfd`},
  {code: `4202`, name: `Algerian`},
  {code: `4202`, name: `Arabic`},
  {code: `4202`, name: `Bedouin`},
  {code: `4202`, name: `Egyptian`},
  {code: `4202`, name: `Iraqi`},
  {code: `4202`, name: `Lebanese`},
  {code: `4202`, name: `Lebenese`},
  {code: `4202`, name: `Moroccan`},
  {code: `4202`, name: `Palestinian`},
  {code: `4202`, name: `Sudanese Arabic`},
  {code: `4202`, name: `Sudanese Spoken Arabic`},
  {code: `4202`, name: `Syrian`},
  {code: `4204`, name: `Hebrew`},
  {code: `4204`, name: `Herrew`},
  {code: `4204`, name: `Israeli`},
  {code: `4206`, name: `Acerian`},
  {code: `4206`, name: `Aramaic`},
  {code: `4206`, name: `Aseriam`},
  {code: `4206`, name: `Assyrian`},
  {code: `4206`, name: `Assyrian (including Aramaic)`},
  {code: `4206`, name: `Assyrian Kildian`},
  {code: `4206`, name: `Assyrian Neo-Aramaic`},
  {code: `4206`, name: `Neo-Syriac`},
  {code: `4206`, name: `Syriac`},
  {code: `4206`, name: `Syriac Aramaic`},
  {code: `4207`, name: `Caldian`},
  {code: `4207`, name: `Chaldean`},
  {code: `4207`, name: `Chaldean Neo-Aramaic`},
  {code: `4207`, name: `Chaldian`},
  {code: `4207`, name: `Cheldean`},
  {code: `4207`, name: `Keldan`},
  {code: `4207`, name: `Kildian`},
  {code: `4207`, name: `Kildian Assyrian`},
  {code: `4208`, name: `Manaean (Mandaic)`},
  {code: `4208`, name: `Mandaean`},
  {code: `4208`, name: `Mandaic`},
  {code: `4208`, name: `Mandi`},
  {code: `4208`, name: `Neo-Mandaic`},
  {code: `4299`, name: `Middle Eastern Semitic Languages, nec`},
  {code: `4300`, name: `Turkic, nfd`},
  {code: `4300`, name: `Turkistani`},
  {code: `4301`, name: `Turk`},
  {code: `4301`, name: `Turkce`},
  {code: `4301`, name: `Turkey`},
  {code: `4301`, name: `Turkihs`},
  {code: `4301`, name: `Turkish`},
  {code: `4302`, name: `Azerbaijan`},
  {code: `4302`, name: `Azerbaijani`},
  {code: `4302`, name: `Azerbaijanian`},
  {code: `4302`, name: `Azeri`},
  {code: `4303`, name: `Tartar`},
  {code: `4303`, name: `Tatar`},
  {code: `4304`, name: `Turkmen`},
  {code: `4305`, name: `Uighur`},
  {code: `4305`, name: `Uighurese`},
  {code: `4305`, name: `Urhur`},
  {code: `4305`, name: `Uyghur`},
  {code: `4305`, name: `Uygur`},
  {code: `4306`, name: `Uzbek`},
  {code: `4399`, name: `Kazakh`},
  {code: `4399`, name: `Kazakstani`},
  {code: `4399`, name: `Kirgiz`},
  {code: `4399`, name: `Turkic, nec`},
  {code: `4900`, name: `Central Asian`},
  {code: `4900`, name: `Other Southwest and Central Asian Languages, nfd`},
  {code: `4901`, name: `Armenian`},
  {code: `4902`, name: `Georgian`},
  {code: `4999`, name: `Chechen`},
  {code: `4999`, name: `Ingush`},
  {code: `4999`, name: `Other Southwest and Central Asian Languages, nec`},
  {code: `5000`, name: `Indian`},
  {code: `5000`, name: `Indie`},
  {code: `5000`, name: `South Asian`},
  {code: `5000`, name: `Southern Asian Languages, nfd`},
  {code: `5000`, name: `Sri Lanka`},
  {code: `5000`, name: `Sri Lankan`},
  {code: `5000`, name: `Sth Asian`},
  {code: `5100`, name: `Dravidian`},
  {code: `5100`, name: `Dravidian, nfd`},
  {code: `5101`, name: `Kanarese`},
  {code: `5101`, name: `Kannada`},
  {code: `5102`, name: `Malayalam`},
  {code: `5103`, name: `Ceylon Tamil`},
  {code: `5103`, name: `Malaysian Tamil`},
  {code: `5103`, name: `Tamail`},
  {code: `5103`, name: `Tamil`},
  {code: `5103`, name: `Tamil Malay`},
  {code: `5103`, name: `Tamil Malayalam`},
  {code: `5103`, name: `Tamils`},
  {code: `5104`, name: `Telgu`},
  {code: `5104`, name: `Telugu`},
  {code: `5105`, name: `Tulu`},
  {code: `5199`, name: `Dravidian, nec`},
  {code: `5199`, name: `Koda`},
  {code: `5199`, name: `Kodava`},
  {code: `5199`, name: `Kolami`},
  {code: `5199`, name: `Kurukh`},
  {code: `5199`, name: `Malto`},
  {code: `5200`, name: `Hindou`},
  {code: `5200`, name: `Hindu`},
  {code: `5200`, name: `Indo Aryan`},
  {code: `5200`, name: `Indo-Aryan, nfd`},
  {code: `5200`, name: `Kutchi`},
  {code: `5200`, name: `Pakistani`},
  {code: `5200`, name: `Sikh`},
  {code: `5201`, name: `Bangalie`},
  {code: `5201`, name: `Bangla`},
  {code: `5201`, name: `Bangladeshi`},
  {code: `5201`, name: `Banglali`},
  {code: `5201`, name: `Bangoli`},
  {code: `5201`, name: `Bangoloy`},
  {code: `5201`, name: `Bengalee`},
  {code: `5201`, name: `Bengali`},
  {code: `5201`, name: `Bengoli`},
  {code: `5202`, name: `Gugrati`},
  {code: `5202`, name: `Gujarati`},
  {code: `5202`, name: `Gujrati`},
  {code: `5202`, name: `Gurati`},
  {code: `5203`, name: `Hindhi`},
  {code: `5203`, name: `Hindi`},
  {code: `5203`, name: `Hindi Marathi`},
  {code: `5203`, name: `Hindi Punjabi`},
  {code: `5203`, name: `Hindi Urdu`},
  {code: `5203`, name: `Hindie`},
  {code: `5203`, name: `Hinds`},
  {code: `5203`, name: `Hindustani`},
  {code: `5203`, name: `Hindustani Urdu`},
  {code: `5203`, name: `Hindy`},
  {code: `5204`, name: `Kokni`},
  {code: `5204`, name: `Konkani`},
  {code: `5205`, name: `Indian Marathi`},
  {code: `5205`, name: `Maharastrian`},
  {code: `5205`, name: `Marathi`},
  {code: `5205`, name: `Marathi Hindi`},
  {code: `5205`, name: `Marathi Indian`},
  {code: `5205`, name: `Marati`},
  {code: `5206`, name: `Gurkhali`},
  {code: `5206`, name: `Nepalese`},
  {code: `5206`, name: `Nepali`},
  {code: `5207`, name: `Landa`},
  {code: `5207`, name: `Panjabi`},
  {code: `5207`, name: `Punjabi`},
  {code: `5207`, name: `Punjbi`},
  {code: `5208`, name: `Sindhi`},
  {code: `5211`, name: `Sinanese`},
  {code: `5211`, name: `Singaleese`},
  {code: `5211`, name: `Singalese`},
  {code: `5211`, name: `Singhala`},
  {code: `5211`, name: `Singhale`},
  {code: `5211`, name: `Singhalese`},
  {code: `5211`, name: `Singhelis`},
  {code: `5211`, name: `Sinhaelies`},
  {code: `5211`, name: `Sinhala`},
  {code: `5211`, name: `Sinhala Tamil`},
  {code: `5211`, name: `Sinhalais`},
  {code: `5211`, name: `Sinhale`},
  {code: `5211`, name: `Sinhalease`},
  {code: `5211`, name: `Sinhalese`},
  {code: `5211`, name: `Sinhalis`},
  {code: `5211`, name: `Sinhaliss`},
  {code: `5211`, name: `Sinhelees`},
  {code: `5211`, name: `Sinhlise`},
  {code: `5212`, name: `Urdi`},
  {code: `5212`, name: `Urdu`},
  {code: `5212`, name: `Urdu Hindi`},
  {code: `5212`, name: `Urdu Hindustani`},
  {code: `5212`, name: `Urdu Punjabi`},
  {code: `5213`, name: `Assamese`},
  {code: `5214`, name: `Dhivehi`},
  {code: `5214`, name: `Divehi`},
  {code: `5214`, name: `Maldivian`},
  {code: `5215`, name: `Kashmiri`},
  {code: `5216`, name: `Oriya`},
  {code: `5217`, name: `Fijian Hindi`},
  {code: `5217`, name: `Fijian Hindustani`},
  {code: `5217`, name: `Fijian Indian`},
  {code: `5299`, name: `Kachchi`},
  {code: `5299`, name: `Bihari`},
  {code: `5299`, name: `Degarlo`},
  {code: `5299`, name: `Indo-Aryan, nec`},
  {code: `5299`, name: `Kumauni`},
  {code: `5299`, name: `Mewari`},
  {code: `5299`, name: `Rajasthani`},
  {code: `5299`, name: `Sanskrit`},
  {code: `5999`, name: `Balti`},
  {code: `5999`, name: `Bhutanese Dzonkha`},
  {code: `5999`, name: `Chamba`},
  {code: `5999`, name: `Dzonglha`},
  {code: `5999`, name: `Dzonkha`},
  {code: `5999`, name: `Manipuri`},
  {code: `5999`, name: `Munda`},
  {code: `5999`, name: `Mundari`},
  {code: `5999`, name: `Naganese`},
  {code: `5999`, name: `Newari`},
  {code: `5999`, name: `Nuristani`},
  {code: `5999`, name: `Other Southern Asian Languages`},
  {code: `5999`, name: `Sikkamese`},
  {code: `5999`, name: `Sumi`},
  {code: `5999`, name: `Triprui`},
  {code: `6000`, name: `SE Asian`},
  {code: `6000`, name: `Southeast Asian`},
  {code: `6000`, name: `Southeast Asian Languages, nfd`},
  {code: `6000`, name: `Stheast Asian`},
  {code: `6100`, name: `Burmese and Related Languages, nfd`},
  {code: `6100`, name: `Chin`},
  {code: `6101`, name: `Burma`},
  {code: `6101`, name: `Burman`},
  {code: `6101`, name: `Burmese`},
  {code: `6101`, name: `Burmese Chin`},
  {code: `6101`, name: `Myanmar`},
  {code: `6102`, name: `Chin Haka`},
  {code: `6102`, name: `Haka Chin`},
  {code: `6103`, name: `Brek`},
  {code: `6103`, name: `Bwe`},
  {code: `6103`, name: `Eastern Kayah`},
  {code: `6103`, name: `Karen`},
  {code: `6103`, name: `Karen Manumanaw`},
  {code: `6103`, name: `Karen Paku`},
  {code: `6103`, name: `Karen Pwo`},
  {code: `6103`, name: `Karen Sgaw`},
  {code: `6103`, name: `Karen S'gaw`},
  {code: `6103`, name: `Karen Thai`},
  {code: `6103`, name: `Karenni`},
  {code: `6103`, name: `Karenni Kayah`},
  {code: `6103`, name: `Karennyi`},
  {code: `6103`, name: `Kayah`},
  {code: `6103`, name: `Kayah Sgaw`},
  {code: `6103`, name: `Manumanaw`},
  {code: `6103`, name: `Paku`},
  {code: `6103`, name: `Pwo`},
  {code: `6103`, name: `Red Karen`},
  {code: `6103`, name: `Sgaw`},
  {code: `6104`, name: `Rohinga`},
  {code: `6104`, name: `Rohingya`},
  {code: `6104`, name: `Rohinja`},
  {code: `6105`, name: `Chin Zome`},
  {code: `6105`, name: `Zo`},
  {code: `6105`, name: `Zome`},
  {code: `6105`, name: `Zomi`},
  {code: `6199`, name: `Arakanese`},
  {code: `6199`, name: `Arkannese`},
  {code: `6199`, name: `Burmese and Related Languages, nec`},
  {code: `6199`, name: `Chin Falam`},
  {code: `6199`, name: `Chin Mara`},
  {code: `6199`, name: `Chin Matu`},
  {code: `6199`, name: `Chin Miro`},
  {code: `6199`, name: `Chin Mizo`},
  {code: `6199`, name: `Chin Senthang`},
  {code: `6199`, name: `Chin Teddim`},
  {code: `6199`, name: `Chin Zotung`},
  {code: `6199`, name: `Falam`},
  {code: `6199`, name: `Jingpho`},
  {code: `6199`, name: `Kachin`},
  {code: `6199`, name: `Khumi`},
  {code: `6199`, name: `Kuki Chin`},
  {code: `6199`, name: `Lisu`},
  {code: `6199`, name: `Mara`},
  {code: `6199`, name: `Matu`},
  {code: `6199`, name: `Matu Chin`},
  {code: `6199`, name: `Mizo`},
  {code: `6199`, name: `Mro`},
  {code: `6199`, name: `Nga La`},
  {code: `6199`, name: `Rawang`},
  {code: `6199`, name: `Senthang`},
  {code: `6199`, name: `Teddim`},
  {code: `6199`, name: `Zotung`},
  {code: `6200`, name: `Hmong-Mien, nfd`},
  {code: `6201`, name: `Hmong`},
  {code: `6201`, name: `Hmong Mien`},
  {code: `6201`, name: `Meo`},
  {code: `6201`, name: `Miao`},
  {code: `6201`, name: `Mong`},
  {code: `6299`, name: `Hmong-Mien, nec`},
  {code: `6300`, name: `Mon-Khmer, nfd`},
  {code: `6301`, name: `Cambodia`},
  {code: `6301`, name: `Cambodian`},
  {code: `6301`, name: `Combodia`},
  {code: `6301`, name: `Kamer`},
  {code: `6301`, name: `Kampuchean`},
  {code: `6301`, name: `Khamer`},
  {code: `6301`, name: `Khemer`},
  {code: `6301`, name: `Khmar`},
  {code: `6301`, name: `Khmer`},
  {code: `6301`, name: `Kmer`},
  {code: `6301`, name: `Kmhere`},
  {code: `6302`, name: `Annamese`},
  {code: `6302`, name: `Viet Nam`},
  {code: `6302`, name: `Vietnam`},
  {code: `6302`, name: `Vietnamese`},
  {code: `6303`, name: `Mon`},
  {code: `6303`, name: `Mon-Khmer`},
  {code: `6303`, name: `Peguan`},
  {code: `6399`, name: `Khasi`},
  {code: `6399`, name: `Khmu`},
  {code: `6399`, name: `Koho`},
  {code: `6399`, name: `Mnong`},
  {code: `6399`, name: `Mon-Khmer, nec`},
  {code: `6399`, name: `Palaung`},
  {code: `6399`, name: `Rengao`},
  {code: `6399`, name: `Sedang`},
  {code: `6400`, name: `Tai, nfd`},
  {code: `6401`, name: `Lad`},
  {code: `6401`, name: `Ladation`},
  {code: `6401`, name: `Lao`},
  {code: `6401`, name: `Laos`},
  {code: `6401`, name: `Laostian`},
  {code: `6401`, name: `Laotian`},
  {code: `6401`, name: `Laotien`},
  {code: `6401`, name: `Laotienne`},
  {code: `6401`, name: `Laotion`},
  {code: `6402`, name: `Siamese`},
  {code: `6402`, name: `Tai`},
  {code: `6402`, name: `Thai`},
  {code: `6402`, name: `Thailand`},
  {code: `6499`, name: `Puyi`},
  {code: `6499`, name: `Shan`},
  {code: `6499`, name: `Tai, nec`},
  {code: `6500`, name: `Bahasa`},
  {code: `6500`, name: `Indonesia`},
  {code: `6500`, name: `Southeast Asian Austronesian Languages, nfd`},
  {code: `6501`, name: `Besayan`},
  {code: `6501`, name: `Bisaya`},
  {code: `6501`, name: `Bisayan`},
  {code: `6501`, name: `Visaya`},
  {code: `6501`, name: `Visayan`},
  {code: `6501`, name: `Visayan Tagalog`},
  {code: `6502`, name: `Cebuan`},
  {code: `6502`, name: `Cebuano`},
  {code: `6503`, name: `Ilicano`},
  {code: `6503`, name: `Ilocano`},
  {code: `6503`, name: `Ilokano`},
  {code: `6504`, name: `Bahasa Indonesia`},
  {code: `6504`, name: `Bahasa Indonesian`},
  {code: `6504`, name: `Indo`},
  {code: `6504`, name: `Indonesia Bahasa`},
  {code: `6504`, name: `Indonesian`},
  {code: `6504`, name: `Indonesian Bahasa`},
  {code: `6505`, name: `Bahasa Malay`},
  {code: `6505`, name: `Bahasa Malaysia`},
  {code: `6505`, name: `Bahasa Malaysian`},
  {code: `6505`, name: `Bidayuh Malay`},
  {code: `6505`, name: `Bruneian`},
  {code: `6505`, name: `Cocos`},
  {code: `6505`, name: `Cocos Island`},
  {code: `6505`, name: `Cocos Malay`},
  {code: `6505`, name: `Malay`},
  {code: `6505`, name: `Malaysian`},
  {code: `6505`, name: `Malaysian Bahasa`},
  {code: `6505`, name: `Malayu`},
  {code: `6505`, name: `Melayu`},
  {code: `6507`, name: `Tetum`},
  {code: `6507`, name: `Tetun`},
  {code: `6507`, name: `Tetun Portuguese`},
  {code: `6507`, name: `Tetuna Indonesia`},
  {code: `6507`, name: `Titun Haka`},
  {code: `6508`, name: `Timorese`},
  {code: `6511`, name: `Filipino Tagalog`},
  {code: `6511`, name: `Tagalo`},
  {code: `6511`, name: `Tagalog`},
  {code: `6511`, name: `Tagalog (Filipino)`},
  {code: `6511`, name: `Tagalog Filipino`},
  {code: `6511`, name: `Tagalog Visayan`},
  {code: `6511`, name: `Tagarlog`},
  {code: `6511`, name: `Talago`},
  {code: `6511`, name: `Tatalog`},
  {code: `6512`, name: `Filipino`},
  {code: `6512`, name: `Phili`},
  {code: `6512`, name: `Philipens`},
  {code: `6512`, name: `Philippines`},
  {code: `6512`, name: `Philippino`},
  {code: `6512`, name: `Phillipines`},
  {code: `6512`, name: `Phillopino`},
  {code: `6512`, name: `Pilipina`},
  {code: `6512`, name: `Pilipino`},
  {code: `6512`, name: `Pilipo`},
  {code: `6513`, name: `Acehnese`},
  {code: `6513`, name: `Achenese`},
  {code: `6513`, name: `Achinese`},
  {code: `6514`, name: `Balinese`},
  {code: `6515`, name: `Bicol Tagalog`},
  {code: `6515`, name: `Bicolano`},
  {code: `6515`, name: `Bikol`},
  {code: `6516`, name: `Iban`},
  {code: `6517`, name: `Hiligaynon`},
  {code: `6517`, name: `Illonggo`},
  {code: `6517`, name: `Ilonggo (Hiligaynon)`},
  {code: `6517`, name: `Ilongo`},
  {code: `6517`, name: `Ilongo (Hiligaynon)`},
  {code: `6518`, name: `Javanese`},
  {code: `6521`, name: `Kapampangan`},
  {code: `6521`, name: `Pampangan`},
  {code: `6521`, name: `Pampango`},
  {code: `6521`, name: `Pangpango`},
  {code: `6599`, name: `Aklanon`},
  {code: `6599`, name: `Basian`},
  {code: `6599`, name: `Batak`},
  {code: `6599`, name: `Buginese`},
  {code: `6599`, name: `Cham`},
  {code: `6599`, name: `Formosan`},
  {code: `6599`, name: `Jarai`},
  {code: `6599`, name: `Kadazan`},
  {code: `6599`, name: `Kalinga`},
  {code: `6599`, name: `Kankanaey`},
  {code: `6599`, name: `Madurese`},
  {code: `6599`, name: `Pangasinan`},
  {code: `6599`, name: `Rade`},
  {code: `6599`, name: `Southeast Asian Austronesian Languages, nec`},
  {code: `6599`, name: `Waray`},
  {code: `6999`, name: `Chabacano`},
  {code: `6999`, name: `Chavacano`},
  {code: `6999`, name: `Other Southeast Asian Languages`},
  {code: `7000`, name: `Eastern Asian`},
  {code: `7000`, name: `Eastern Asian Languages, nfd`},
  {code: `7100`, name: `Chang Chow`},
  {code: `7100`, name: `Chang Chow Fu`},
  {code: `7100`, name: `China`},
  {code: `7100`, name: `Chinchchou`},
  {code: `7100`, name: `Chinese`},
  {code: `7100`, name: `Chinese dialect`},
  {code: `7100`, name: `Chinese, nfd`},
  {code: `7100`, name: `Sinitic`},
  {code: `7101`, name: `Canton`},
  {code: `7101`, name: `Cantonese`},
  {code: `7101`, name: `Hong Kong`},
  {code: `7101`, name: `Swatow`},
  {code: `7101`, name: `Tai Shan`},
  {code: `7101`, name: `Yue`},
  {code: `7102`, name: `Chinese Hakka`},
  {code: `7102`, name: `Chinese Hucka`},
  {code: `7102`, name: `Hacca`},
  {code: `7102`, name: `Hacka`},
  {code: `7102`, name: `Hakah`},
  {code: `7102`, name: `Hakha`},
  {code: `7102`, name: `Hakka`},
  {code: `7102`, name: `Hakka Chinese`},
  {code: `7102`, name: `Hakkah`},
  {code: `7102`, name: `Hakkar`},
  {code: `7102`, name: `Harka`},
  {code: `7102`, name: `Harrka`},
  {code: `7102`, name: `Hokka`},
  {code: `7102`, name: `Hukka`},
  {code: `7104`, name: `Mandarin`},
  {code: `7104`, name: `Mandarine`},
  {code: `7104`, name: `Mandren`},
  {code: `7104`, name: `Mandrin`},
  {code: `7104`, name: `Putonghua`},
  {code: `7106`, name: `Shanghai`},
  {code: `7106`, name: `Shanghai Dialect`},
  {code: `7106`, name: `Shanghaiese`},
  {code: `7106`, name: `Shanghainese`},
  {code: `7106`, name: `Wu`},
  {code: `7107`, name: `Chao Zhou`},
  {code: `7107`, name: `Chaochon`},
  {code: `7107`, name: `Chauv Cou`},
  {code: `7107`, name: `Chewchow`},
  {code: `7107`, name: `Chinese Tio Chiu`},
  {code: `7107`, name: `Chiu Chou`},
  {code: `7107`, name: `Cho Chau`},
  {code: `7107`, name: `Chow Chiw`},
  {code: `7107`, name: `Chue Chow`},
  {code: `7107`, name: `Chui Chow`},
  {code: `7107`, name: `Chuo Chao`},
  {code: `7107`, name: `Foo Chow`},
  {code: `7107`, name: `Foochow`},
  {code: `7107`, name: `Fookien`},
  {code: `7107`, name: `Fu jian`},
  {code: `7107`, name: `Fu Zhou dialect`},
  {code: `7107`, name: `Fuchian`},
  {code: `7107`, name: `Fuchien`},
  {code: `7107`, name: `Fuchow`},
  {code: `7107`, name: `Fukien`},
  {code: `7107`, name: `Fukienese`},
  {code: `7107`, name: `Hainam`},
  {code: `7107`, name: `Hainanese`},
  {code: `7107`, name: `Hockian`},
  {code: `7107`, name: `Hokien`},
  {code: `7107`, name: `Hokkien`},
  {code: `7107`, name: `Min Nan`},
  {code: `7107`, name: `Minnan`},
  {code: `7107`, name: `Taiwanese`},
  {code: `7107`, name: `Tao Chow`},
  {code: `7107`, name: `T-Chow`},
  {code: `7107`, name: `Teachieu`},
  {code: `7107`, name: `Techao`},
  {code: `7107`, name: `Tei Chow`},
  {code: `7107`, name: `Teo Chauv`},
  {code: `7107`, name: `Teo Chew`},
  {code: `7107`, name: `Teo Chiew`},
  {code: `7107`, name: `Teochew`},
  {code: `7107`, name: `Teochiu`},
  {code: `7107`, name: `Teow Chew`},
  {code: `7107`, name: `Tie Chiu`},
  {code: `7107`, name: `Tie Chiw`},
  {code: `7107`, name: `Tieu Chau`},
  {code: `7107`, name: `Tieu Chow`},
  {code: `7107`, name: `To Chu`},
  {code: `7107`, name: `Tochew`},
  {code: `7107`, name: `Trieu Chau`},
  {code: `7199`, name: `Chinese, nec`},
  {code: `7199`, name: `Hunan`},
  {code: `7199`, name: `Hunanese`},
  {code: `7201`, name: `Japan`},
  {code: `7201`, name: `Japanese`},
  {code: `7201`, name: `Nippon`},
  {code: `7301`, name: `Korean`},
  {code: `7301`, name: `South Korean`},
  {code: `7900`, name: `Other Eastern Asian Languages, nfd`},
  {code: `7901`, name: `Bhotia`},
  {code: `7901`, name: `Tibetan`},
  {code: `7902`, name: `Mongol`},
  {code: `7902`, name: `Mongolian`},
  {code: `7999`, name: `Ainu`},
  {code: `7999`, name: `Manchu`},
  {code: `7999`, name: `Other Eastern Asian Languages, nec`},
  {code: `7999`, name: `Paiwan`},
  {code: `7999`, name: `Santa`},
  {code: `7999`, name: `Tungus`},
  {code: `8000`, name: `Aboriginal`},
  {code: `8000`, name: `Aboriginal dialect`},
  {code: `8000`, name: `Aboriginal East`},
  {code: `8000`, name: `Aboriginal language`},
  {code: `8000`, name: `Aboriginal lingo`},
  {code: `8000`, name: `Aboriginal North`},
  {code: `8000`, name: `Aboriginal West Coast`},
  {code: `8000`, name: `Aborigine`},
  {code: `8000`, name: `Australian Aboriginal`},
  {code: `8000`, name: `Australian Aboriginal language not given`},
  {code: `8000`, name: `Australian Creoles`},
  {code: `8000`, name: `Australian Indigenous`},
  {code: `8000`, name: `Australian Indigenous Languages, nfd`},
  {code: `8000`, name: `Creole Aust`},
  {code: `8000`, name: `East Aboriginal`},
  {code: `8000`, name: `Indigenous`},
  {code: `8000`, name: `Indigenous language`},
  {code: `8000`, name: `Koori`},
  {code: `8000`, name: `Murri`},
  {code: `8000`, name: `Ngali`},
  {code: `8000`, name: `Ngari`},
  {code: `8000`, name: `Northern Aboriginal`},
  {code: `8000`, name: `Nunga`},
  {code: `8000`, name: `Phil`},
  {code: `8000`, name: `Pit`},
  {code: `8000`, name: `Tribal`},
  {code: `8000`, name: `Tribal language`},
  {code: `8000`, name: `Wangu`},
  {code: `8000`, name: `Wanybarran`},
  {code: `8000`, name: `War`},
  {code: `8100`, name: `Arnhem Land and Daly River Region Languages, nfd`},
  {code: `8100`, name: `Daly River language`},
  {code: `8100`, name: `N/E Arnham Land Aboriginal dialect`},
  {code: `8101`, name: `Andilagwa`},
  {code: `8101`, name: `Andiljaukwa`},
  {code: `8101`, name: `Andilyakwa`},
  {code: `8101`, name: `Andilyaugwa`},
  {code: `8101`, name: `Aninailyakwa`},
  {code: `8101`, name: `Anindilakwa`},
  {code: `8101`, name: `Anindiljaugwa`},
  {code: `8101`, name: `Anindilyaga`},
  {code: `8101`, name: `Anindilyagwa`},
  {code: `8101`, name: `Anindilyakna`},
  {code: `8101`, name: `Anindilyakua`},
  {code: `8101`, name: `Anindilyakwa`},
  {code: `8101`, name: `Anindilyakwa - Kriol`},
  {code: `8101`, name: `Anindilyaugwa`},
  {code: `8101`, name: `Aninilyakwa`},
  {code: `8101`, name: `Groote`},
  {code: `8101`, name: `Groote Eylandt`},
  {code: `8101`, name: `Ingura`},
  {code: `8101`, name: `Wanindilyaugwa`},
  {code: `8111`, name: `Gunmarung`},
  {code: `8111`, name: `Mau`},
  {code: `8111`, name: `Maung`},
  {code: `8111`, name: `Maw`},
  {code: `8111`, name: `Mawng`},
  {code: `8111`, name: `Mawung`},
  {code: `8113`, name: `Nangikurrunggurr`},
  {code: `8113`, name: `Nangkykurungurr`},
  {code: `8113`, name: `Nangykurungurr`},
  {code: `8113`, name: `Ngaagi Kurunggurr`},
  {code: `8113`, name: `Ngancikurrungurr`},
  {code: `8113`, name: `Ngangikurangurr`},
  {code: `8113`, name: `Ngangikurngurr`},
  {code: `8113`, name: `Ngangikurrgurr`},
  {code: `8113`, name: `Ngan'gikurunggurr`},
  {code: `8113`, name: `Ngangikurungurr`},
  {code: `8113`, name: `Ngangiwumirr`},
  {code: `8113`, name: `Ngan'giwumirri`},
  {code: `8113`, name: `Ngangkikurungurr`},
  {code: `8113`, name: `Nganikurungurr`},
  {code: `8113`, name: `Ngenkikurrunggur`},
  {code: `8113`, name: `Ngukkurra`},
  {code: `8114`, name: `Nuggaboju`},
  {code: `8114`, name: `Nugubuyu`},
  {code: `8114`, name: `Nungabuju`},
  {code: `8114`, name: `Nungabuyu`},
  {code: `8114`, name: `Nunggubuyu`},
  {code: `8114`, name: `Nungubuyu`},
  {code: `8114`, name: `Wubuy`},
  {code: `8115`, name: `Kaltuy`},
  {code: `8115`, name: `Rainbargo`},
  {code: `8115`, name: `Rambaranga`},
  {code: `8115`, name: `Rambarrnga`},
  {code: `8115`, name: `Ramberranga`},
  {code: `8115`, name: `Rembaranga`},
  {code: `8115`, name: `Rembarrnga`},
  {code: `8117`, name: `Tiwi`},
  {code: `8117`, name: `Tiwi island`},
  {code: `8117`, name: `Tunivi`},
  {code: `8121`, name: `Alawa`},
  {code: `8121`, name: `Allaua`},
  {code: `8122`, name: `Dalabon`},
  {code: `8122`, name: `Dalbon`},
  {code: `8123`, name: `Gudanji`},
  {code: `8123`, name: `Gudanyi`},
  {code: `8123`, name: `Gurdanji`},
  {code: `8123`, name: `Kurdanji`},
  {code: `8123`, name: `Ngarnga`},
  {code: `8127`, name: `Iwadja`},
  {code: `8127`, name: `Iwaidja`},
  {code: `8127`, name: `Iwaja`},
  {code: `8127`, name: `Jiwadja`},
  {code: `8128`, name: `Djamindjung`},
  {code: `8128`, name: `Jaminjung`},
  {code: `8131`, name: `Chauan`},
  {code: `8131`, name: `Jarwin`},
  {code: `8131`, name: `Jarwon`},
  {code: `8131`, name: `Jawoyn`},
  {code: `8132`, name: `Jingalu Mudbura`},
  {code: `8132`, name: `Jingili`},
  {code: `8132`, name: `Jingilli`},
  {code: `8132`, name: `Jingulu`},
  {code: `8133`, name: `Gambalang`},
  {code: `8133`, name: `Ganbarlang`},
  {code: `8133`, name: `Gunbulan`},
  {code: `8133`, name: `Kunbarlang`},
  {code: `8136`, name: `Laragia`},
  {code: `8136`, name: `Larakia`},
  {code: `8136`, name: `Larrakia`},
  {code: `8136`, name: `Larrakiya`},
  {code: `8137`, name: `Malak`},
  {code: `8137`, name: `Malak Malak`},
  {code: `8137`, name: `Muluk Muluk`},
  {code: `8138`, name: `Mangarei`},
  {code: `8138`, name: `Mangarayi`},
  {code: `8138`, name: `Mangarrayi`},
  {code: `8138`, name: `Munga`},
  {code: `8138`, name: `Mungari`},
  {code: `8141`, name: `Mari Ngarr`},
  {code: `8141`, name: `Maringar`},
  {code: `8141`, name: `Maringarr`},
  {code: `8141`, name: `Marin-Ngarr`},
  {code: `8141`, name: `Marri`},
  {code: `8141`, name: `Meil`},
  {code: `8142`, name: `Marra`},
  {code: `8143`, name: `Mari Dhiyel`},
  {code: `8143`, name: `Maridhiel`},
  {code: `8143`, name: `Marithiel`},
  {code: `8143`, name: `Marrithial`},
  {code: `8143`, name: `Marrithiyel`},
  {code: `8144`, name: `Matngala`},
  {code: `8146`, name: `Muinpatta`},
  {code: `8146`, name: `Muinpotta`},
  {code: `8146`, name: `Muintatta`},
  {code: `8146`, name: `Murinbada`},
  {code: `8146`, name: `Murinpatha`},
  {code: `8146`, name: `Murinypata`},
  {code: `8146`, name: `Murinypatha`},
  {code: `8146`, name: `Murrinh Patha`},
  {code: `8146`, name: `Murrinhpatha`},
  {code: `8147`, name: `Nakara`},
  {code: `8147`, name: `Na-Kara`},
  {code: `8147`, name: `Nakkara`},
  {code: `8147`, name: `Narkarrar`},
  {code: `8148`, name: `Djeebbana`},
  {code: `8148`, name: `Gunavidji`},
  {code: `8148`, name: `Gunaviji`},
  {code: `8148`, name: `Gwornabidji`},
  {code: `8148`, name: `Jabanna`},
  {code: `8148`, name: `Ndjebbana`},
  {code: `8148`, name: `Ndjebbana (Gunavidji)`},
  {code: `8148`, name: `Ndjeebbana`},
  {code: `8151`, name: `Ngalakgan`},
  {code: `8152`, name: `Ngaliwurru`},
  {code: `8152`, name: `Ngaliwuru`},
  {code: `8152`, name: `Ngalliwurru`},
  {code: `8153`, name: `Nungali`},
  {code: `8154`, name: `Wambaia`},
  {code: `8154`, name: `Wambaya`},
  {code: `8155`, name: `Wadaman`},
  {code: `8155`, name: `Wardaman`},
  {code: `8156`, name: `Amurag`},
  {code: `8156`, name: `Amurdak`},
  {code: `8156`, name: `Umorrdak`},
  {code: `8157`, name: `Gaarwa`},
  {code: `8157`, name: `Garawa`},
  {code: `8157`, name: `Garrawa`},
  {code: `8157`, name: `Garrwa`},
  {code: `8157`, name: `Grawa`},
  {code: `8157`, name: `Karawa`},
  {code: `8157`, name: `Kariwa`},
  {code: `8157`, name: `Karrawar`},
  {code: `8157`, name: `Karrwa`},
  {code: `8157`, name: `Karwa`},
  {code: `8157`, name: `Korrawa`},
  {code: `8158`, name: `Djerait`},
  {code: `8158`, name: `Guwema`},
  {code: `8158`, name: `Kuwema`},
  {code: `8161`, name: `Marimanindji`},
  {code: `8161`, name: `Marramaninyshi`},
  {code: `8162`, name: `Ngandi`},
  {code: `8163`, name: `Waanji`},
  {code: `8163`, name: `Waanyi`},
  {code: `8163`, name: `Wanji`},
  {code: `8163`, name: `Wanyi`},
  {code: `8164`, name: `Wagaman`},
  {code: `8164`, name: `Wageman`},
  {code: `8164`, name: `Wagiman`},
  {code: `8164`, name: `Wagoman`},
  {code: `8164`, name: `Wogeman`},
  {code: `8165`, name: `Aniula`},
  {code: `8165`, name: `Anula`},
  {code: `8165`, name: `Anyula`},
  {code: `8165`, name: `Yanalla`},
  {code: `8165`, name: `Yanula`},
  {code: `8165`, name: `Yanuwa`},
  {code: `8165`, name: `Yanyula`},
  {code: `8165`, name: `Yanyuwa`},
  {code: `8165`, name: `Yanyuwa (Anula)`},
  {code: `8166`, name: `Maridan`},
  {code: `8166`, name: `Marridan`},
  {code: `8166`, name: `Marridan (Maridan)`},
  {code: `8170`, name: `Kunwinjkuan`},
  {code: `8170`, name: `Kunwinjkuan, nfd`},
  {code: `8171`, name: `Gundjajeimi`},
  {code: `8171`, name: `Gundjeihmi`},
  {code: `8172`, name: `Gunei`},
  {code: `8172`, name: `Kune`},
  {code: `8173`, name: `Kuninjku`},
  {code: `8174`, name: `Gunawingu`},
  {code: `8174`, name: `Gunawitji`},
  {code: `8174`, name: `Gunwinggu`},
  {code: `8174`, name: `Gunwingo`},
  {code: `8174`, name: `Gunwingu`},
  {code: `8174`, name: `Gunwinku`},
  {code: `8174`, name: `Kunawinjku`},
  {code: `8174`, name: `Kunawinku`},
  {code: `8174`, name: `Kunkingku`},
  {code: `8174`, name: `Kunwing`},
  {code: `8174`, name: `Kunwinggu`},
  {code: `8174`, name: `Kunwinjku`},
  {code: `8174`, name: `Kunwinku`},
  {code: `8174`, name: `Kuwinku`},
  {code: `8175`, name: `Maiali`},
  {code: `8175`, name: `Mayali`},
  {code: `8175`, name: `Mayeli`},
  {code: `8175`, name: `Miali`},
  {code: `8175`, name: `Mialli`},
  {code: `8179`, name: `Kunwinjkuan, nec`},
  {code: `8180`, name: `Burarran`},
  {code: `8180`, name: `Burarran, nfd`},
  {code: `8181`, name: `Bara`},
  {code: `8181`, name: `Barada`},
  {code: `8181`, name: `Barara`},
  {code: `8181`, name: `Bararra`},
  {code: `8181`, name: `Barea`},
  {code: `8181`, name: `Bawera`},
  {code: `8181`, name: `Birarra`},
  {code: `8181`, name: `Brada`},
  {code: `8181`, name: `Brarra`},
  {code: `8181`, name: `Brarrda`},
  {code: `8181`, name: `Brarrua`},
  {code: `8181`, name: `Burada`},
  {code: `8181`, name: `Burara`},
  {code: `8181`, name: `Burarra`},
  {code: `8181`, name: `Burrara`},
  {code: `8181`, name: `Burrarda`},
  {code: `8181`, name: `Burrarr`},
  {code: `8181`, name: `Burrarra`},
  {code: `8182`, name: `Gunardba`},
  {code: `8182`, name: `Gun-nartpa`},
  {code: `8183`, name: `Gorogone`},
  {code: `8183`, name: `Gungurugoni`},
  {code: `8183`, name: `Gurrgoni`},
  {code: `8183`, name: `Gurr-goni`},
  {code: `8189`, name: `Burarran, nec`},
  {code: `8199`, name: `Arnhem Land and Daly River Region Languages, nec`},
  {code: `8199`, name: `Emmi`},
  {code: `8199`, name: `Geimbio`},
  {code: `8199`, name: `Gimba`},
  {code: `8199`, name: `Hongalla Hongalla`},
  {code: `8199`, name: `Jabaru`},
  {code: `8199`, name: `Jugul`},
  {code: `8199`, name: `Kaiali`},
  {code: `8199`, name: `Kokori`},
  {code: `8199`, name: `Kumertuo`},
  {code: `8199`, name: `Kutji`},
  {code: `8199`, name: `Miwa`},
  {code: `8199`, name: `Ngara`},
  {code: `8199`, name: `Umbia`},
  {code: `8199`, name: `Warrangari`},
  {code: `8199`, name: `Witchi`},
  {code: `8200`, name: `Alyere`},
  {code: `8200`, name: `Yolgu`},
  {code: `8200`, name: `Yolgu Matha`},
  {code: `8200`, name: `Yolngu`},
  {code: `8200`, name: `Yolngu Matha`},
  {code: `8200`, name: `Yolngu Matha, nfd`},
  {code: `8200`, name: `Yolnu Mata`},
  {code: `8200`, name: `Yulgnu`},
  {code: `8210`, name: `Dhaangu`},
  {code: `8210`, name: `Dhangu`},
  {code: `8210`, name: `Dhangu, nfd`},
  {code: `8211`, name: `Galpu`},
  {code: `8211`, name: `Kalpu`},
  {code: `8212`, name: `Golumala`},
  {code: `8213`, name: `Wanguri`},
  {code: `8213`, name: `Wangurri`},
  {code: `8219`, name: `Dhangu, nec`},
  {code: `8220`, name: `Daii`},
  {code: `8220`, name: `Dhai`},
  {code: `8220`, name: `Dhay'yi`},
  {code: `8220`, name: `Dhay'yi, nfd`},
  {code: `8220`, name: `Taii`},
  {code: `8221`, name: `Dalawangu`},
  {code: `8221`, name: `Dhalwangu`},
  {code: `8221`, name: `Dhalwanu`},
  {code: `8221`, name: `Thalwungu`},
  {code: `8222`, name: `Djarrwark`},
  {code: `8229`, name: `Dhay'yi, nec`},
  {code: `8230`, name: `Dhuwal`},
  {code: `8230`, name: `Dhuwal, nfd`},
  {code: `8231`, name: `Dhambarrpuynu`},
  {code: `8231`, name: `Djabarrpsynga`},
  {code: `8231`, name: `Djambapuingu`},
  {code: `8231`, name: `Djambarapuyngu`},
  {code: `8231`, name: `Djambarrbuygu`},
  {code: `8231`, name: `Djambarrbuyngu`},
  {code: `8231`, name: `Djambarrbynu`},
  {code: `8231`, name: `Djambarrpugyu`},
  {code: `8231`, name: `Djambarrpunu`},
  {code: `8231`, name: `Djambarrpuy`},
  {code: `8231`, name: `Djambarrpuyagu`},
  {code: `8231`, name: `Djambarrpuynau`},
  {code: `8231`, name: `Djambarrpuynga`},
  {code: `8231`, name: `Djambarrpuyngu`},
  {code: `8231`, name: `Djambarrpuynju`},
  {code: `8231`, name: `Djambarrpuynu`},
  {code: `8231`, name: `Djambarrpuyu`},
  {code: `8231`, name: `Djambarruuyngu`},
  {code: `8231`, name: `Durili`},
  {code: `8231`, name: `English Djambarrpuyngou`},
  {code: `8231`, name: `Jumbabuingo`},
  {code: `8232`, name: `Dhuwal Djapu`},
  {code: `8232`, name: `Djapu`},
  {code: `8232`, name: `Djupu`},
  {code: `8233`, name: `Daatiwuy`},
  {code: `8233`, name: `Datiwuy`},
  {code: `8234`, name: `Manarrngu`},
  {code: `8234`, name: `Marrangu`},
  {code: `8235`, name: `Liyagalawumirr`},
  {code: `8236`, name: `Leiagawumirr`},
  {code: `8236`, name: `Liyagawumirr`},
  {code: `8239`, name: `Dhuwal, nec`},
  {code: `8239`, name: `Marrakulu`},
  {code: `8240`, name: `Dhuwala`},
  {code: `8240`, name: `Dhuwala, nfd`},
  {code: `8242`, name: `Gamatj`},
  {code: `8242`, name: `Gumadji`},
  {code: `8242`, name: `Gumatj`},
  {code: `8242`, name: `Gumatji`},
  {code: `8242`, name: `Gumats`},
  {code: `8242`, name: `Kumatj`},
  {code: `8243`, name: `English Gupapuyngu`},
  {code: `8243`, name: `Gapapuyngu`},
  {code: `8243`, name: `Gubabuyngu`},
  {code: `8243`, name: `Gubapunuy`},
  {code: `8243`, name: `Gupanuyngu`},
  {code: `8243`, name: `Gupapungu`},
  {code: `8243`, name: `Gupapuyngu`},
  {code: `8243`, name: `Gupapuynju`},
  {code: `8243`, name: `Gupapuynu`},
  {code: `8243`, name: `Gupapuyungu`},
  {code: `8243`, name: `Gupapuyuu`},
  {code: `8243`, name: `Kopapingu`},
  {code: `8244`, name: `Guyamirrilili`},
  {code: `8246`, name: `Mangalili`},
  {code: `8246`, name: `Manggalili`},
  {code: `8247`, name: `Wubulkarra`},
  {code: `8249`, name: `Dhuwala, nec`},
  {code: `8250`, name: `Balmawi`},
  {code: `8250`, name: `Balmbi`},
  {code: `8250`, name: `Djinang`},
  {code: `8250`, name: `Djinang, nfd`},
  {code: `8250`, name: `Jandjinang`},
  {code: `8250`, name: `Jand-Jinung`},
  {code: `8250`, name: `Yandjinung`},
  {code: `8251`, name: `Wulagi`},
  {code: `8251`, name: `Wulaki`},
  {code: `8251`, name: `Wurlaki`},
  {code: `8259`, name: `Djinang, nec`},
  {code: `8259`, name: `Manharrngu`},
  {code: `8259`, name: `Mildjingi`},
  {code: `8260`, name: `Djinba, nfd`},
  {code: `8260`, name: `Kurka`},
  {code: `8261`, name: `Ganalbingu`},
  {code: `8262`, name: `Djinba`},
  {code: `8263`, name: `Mandjalpingu`},
  {code: `8263`, name: `Manydjalpingu`},
  {code: `8263`, name: `Manyjalpingu`},
  {code: `8269`, name: `Dabi`},
  {code: `8269`, name: `Djinba, nec`},
  {code: `8270`, name: `Yakuy`},
  {code: `8270`, name: `Yakuy, nfd`},
  {code: `8271`, name: `Ritharngu`},
  {code: `8271`, name: `Ritharrngu`},
  {code: `8272`, name: `Wagilag`},
  {code: `8272`, name: `Wagilak`},
  {code: `8272`, name: `Wargulak`},
  {code: `8279`, name: `Yakuy, nec`},
  {code: `8281`, name: `Nhangu`},
  {code: `8282`, name: `Jamango`},
  {code: `8282`, name: `Yan Nhangu`},
  {code: `8282`, name: `Ya-nhangu`},
  {code: `8282`, name: `Yannhangu`},
  {code: `8289`, name: `Gamalangga`},
  {code: `8289`, name: `Golpa`},
  {code: `8289`, name: `Gorryindi`},
  {code: `8289`, name: `Gunbirrtji`},
  {code: `8289`, name: `Malarra`},
  {code: `8289`, name: `Nhangu, nec`},
  {code: `8291`, name: `Dhuwaya`},
  {code: `8292`, name: `Djangu`},
  {code: `8293`, name: `Madarrpa`},
  {code: `8293`, name: `Mardapa`},
  {code: `8293`, name: `Marrarapa`},
  {code: `8294`, name: `Warameri`},
  {code: `8294`, name: `Warramiri`},
  {code: `8294`, name: `Warumeri`},
  {code: `8295`, name: `Rirratjingu`},
  {code: `8299`, name: `Djaba`},
  {code: `8299`, name: `Nangga`},
  {code: `8299`, name: `Other Yolngu Matha, nec`},
  {code: `8300`, name: `Cape York Aboriginal`},
  {code: `8300`, name: `Cape York Peninsula Aboriginal`},
  {code: `8300`, name: `Cape York Peninsula Languages, nfd`},
  {code: `8300`, name: `Gugu`},
  {code: `8300`, name: `Koko`},
  {code: `8301`, name: `Gugu Yalandji`},
  {code: `8301`, name: `Gugu Yalanj`},
  {code: `8301`, name: `Gugu Yalanji`},
  {code: `8301`, name: `Jelanji`},
  {code: `8301`, name: `Ku Ku Yalangi`},
  {code: `8301`, name: `Kuku Yalandji`},
  {code: `8301`, name: `Kuku Yalangi`},
  {code: `8301`, name: `Kuku Yalanji`},
  {code: `8301`, name: `Kukyaoanji`},
  {code: `8301`, name: `Kyahara`},
  {code: `8301`, name: `Yalandji`},
  {code: `8301`, name: `Yung Kurara`},
  {code: `8301`, name: `Yungurara`},
  {code: `8301`, name: `Yunkurara`},
  {code: `8302`, name: `Gugu Yimidir`},
  {code: `8302`, name: `Gugu Yimidjir`},
  {code: `8302`, name: `Gugu Yimithirr`},
  {code: `8302`, name: `Guguyimithin`},
  {code: `8302`, name: `Guugu Yimidhirr`},
  {code: `8302`, name: `Guugu Yimithirr`},
  {code: `8303`, name: `Gugu Jao`},
  {code: `8303`, name: `Gugu Yau`},
  {code: `8303`, name: `Guugu Yau`},
  {code: `8303`, name: `Jao`},
  {code: `8303`, name: `Kao`},
  {code: `8303`, name: `Koka Yao`},
  {code: `8303`, name: `Kuku Ya O`},
  {code: `8303`, name: `Kuku Ya'o`},
  {code: `8303`, name: `Kuuku Yau`},
  {code: `8303`, name: `Kuuku-ya'u`},
  {code: `8304`, name: `Mungkan`},
  {code: `8304`, name: `Munican`},
  {code: `8304`, name: `Munkan`},
  {code: `8304`, name: `Wik Hungkan`},
  {code: `8304`, name: `Wik Monkan`},
  {code: `8304`, name: `Wik Munggan`},
  {code: `8304`, name: `Wik Mungkan`},
  {code: `8304`, name: `Wik Mungken`},
  {code: `8304`, name: `Wik Mungknh`},
  {code: `8304`, name: `Wik Mvngkan`},
  {code: `8304`, name: `Wika Munkan`},
  {code: `8304`, name: `Wikmungkan`},
  {code: `8304`, name: `Wikmunkan`},
  {code: `8304`, name: `Woran Am`},
  {code: `8305`, name: `Djabugay`},
  {code: `8305`, name: `Tjapukai`},
  {code: `8306`, name: `Dyirbal`},
  {code: `8306`, name: `Jirrabal`},
  {code: `8307`, name: `Giramai`},
  {code: `8307`, name: `Girramay`},
  {code: `8307`, name: `Girramy`},
  {code: `8307`, name: `Girrimay`},
  {code: `8308`, name: `Berang`},
  {code: `8308`, name: `Bohran`},
  {code: `8308`, name: `Koko Bera`},
  {code: `8308`, name: `Koko-bera`},
  {code: `8308`, name: `Konanin`},
  {code: `8311`, name: `Daiyuri`},
  {code: `8311`, name: `Kuuk Thayorre`},
  {code: `8311`, name: `Taiol`},
  {code: `8311`, name: `Thaaryore`},
  {code: `8311`, name: `Thaayore`},
  {code: `8311`, name: `Thayorre`},
  {code: `8311`, name: `Tyorre`},
  {code: `8312`, name: `Lama Lama`},
  {code: `8312`, name: `Lamalama`},
  {code: `8313`, name: `Idinji`},
  {code: `8313`, name: `Yidiny`},
  {code: `8314`, name: `Wik Ngathan`},
  {code: `8314`, name: `Wik Ngathana`},
  {code: `8315`, name: `Alngith`},
  {code: `8316`, name: `Gugu Muminh`},
  {code: `8316`, name: `Kugu Muminh`},
  {code: `8317`, name: `Morrobalama`},
  {code: `8318`, name: `Thaynakwith`},
  {code: `8321`, name: `Yupangathi`},
  {code: `8322`, name: `Tjungundji`},
  {code: `8399`, name: `Ayan`},
  {code: `8399`, name: `Cape York Peninsula Languages, nec`},
  {code: `8399`, name: `Dangedl`},
  {code: `8399`, name: `Eacham`},
  {code: `8399`, name: `Kaanju`},
  {code: `8399`, name: `Kanju`},
  {code: `8399`, name: `Kunjen`},
  {code: `8399`, name: `Narnar`},
  {code: `8399`, name: `Olgol`},
  {code: `8399`, name: `Umpila`},
  {code: `8399`, name: `Yir Yoront`},
  {code: `8400`, name: `Thursday Is`},
  {code: `8400`, name: `Thursday Island`},
  {code: `8400`, name: `TI language`},
  {code: `8400`, name: `Torres Strait`},
  {code: `8400`, name: `Torres Strait Island`},
  {code: `8400`, name: `Torres Strait Island Languages, nfd`},
  {code: `8400`, name: `Torres Strait Islander`},
  {code: `8401`, name: `Badu`},
  {code: `8401`, name: `Bulgai`},
  {code: `8401`, name: `Kala Kawa Ya`},
  {code: `8401`, name: `Kala Kawaw Ya`},
  {code: `8401`, name: `Kala Lagau Ya`},
  {code: `8401`, name: `Kala Lagaw`},
  {code: `8401`, name: `Kala Lagaw Kriol`},
  {code: `8401`, name: `Kala Lagaw Ya`},
  {code: `8401`, name: `Kalakuwiya`},
  {code: `8401`, name: `Kalalaguya`},
  {code: `8401`, name: `Kalaw Kawa Ya`},
  {code: `8401`, name: `Kalaw Kawaw Ya`},
  {code: `8401`, name: `Kalaw Kawaw Ya/Kalaw Lagaw Ya`},
  {code: `8401`, name: `Kalaw Lagaw Ya`},
  {code: `8401`, name: `Kalaw Lagaw Ya (Kalaw Kawa Ya)`},
  {code: `8401`, name: `Kalawga`},
  {code: `8401`, name: `Kky`},
  {code: `8401`, name: `Mabuiag`},
  {code: `8401`, name: `Mabuyag`},
  {code: `8402`, name: `Maer`},
  {code: `8402`, name: `Meram`},
  {code: `8402`, name: `Meriam`},
  {code: `8402`, name: `Meriam Meir`},
  {code: `8402`, name: `Meriam Mir`},
  {code: `8402`, name: `Meryam`},
  {code: `8402`, name: `Miriam`},
  {code: `8402`, name: `Miriam Kriole`},
  {code: `8402`, name: `Miriam Mer`},
  {code: `8403`, name: `Broken`},
  {code: `8403`, name: `Broken Eng`},
  {code: `8403`, name: `Broken English`},
  {code: `8403`, name: `Creole Iland`},
  {code: `8403`, name: `Creole Torres Strait`},
  {code: `8403`, name: `Criole TSI`},
  {code: `8403`, name: `Iland Creole`},
  {code: `8403`, name: `Torres Strait Creole`},
  {code: `8403`, name: `Torres Strait Creole (Broken)`},
  {code: `8403`, name: `Torres Strait Pigeon`},
  {code: `8403`, name: `TSI Criole`},
  {code: `8403`, name: `Yumplatok`},
  {code: `8403`, name: `Yumplatok (Torres Strait Creole)`},
  {code: `8500`, name: `Northern Desert Fringe Area Languages, nfd`},
  {code: `8504`, name: `Bilinarra`},
  {code: `8504`, name: `Bilingara`},
  {code: `8504`, name: `Bilinurra`},
  {code: `8505`, name: `Coorinji`},
  {code: `8505`, name: `Curindji`},
  {code: `8505`, name: `Gurindji`},
  {code: `8505`, name: `Guringi`},
  {code: `8505`, name: `Gurinji`},
  {code: `8505`, name: `Koorignie`},
  {code: `8505`, name: `Korindji`},
  {code: `8505`, name: `Koringi`},
  {code: `8505`, name: `Kurindi`},
  {code: `8505`, name: `Kurnijie`},
  {code: `8505`, name: `Kuurrinjtji`},
  {code: `8505`, name: `Kwaranjee`},
  {code: `8506`, name: `Gurindji Kriol`},
  {code: `8507`, name: `Djaru`},
  {code: `8507`, name: `Dyaru`},
  {code: `8507`, name: `Jarroo`},
  {code: `8507`, name: `Jarrou`},
  {code: `8507`, name: `Jarru`},
  {code: `8507`, name: `Jaru`},
  {code: `8507`, name: `Jaru (Djaru)`},
  {code: `8507`, name: `Kabiri`},
  {code: `8507`, name: `Meening`},
  {code: `8507`, name: `Nining`},
  {code: `8507`, name: `Nyinin`},
  {code: `8507`, name: `Tjaru`},
  {code: `8508`, name: `Light Warlpiri`},
  {code: `8511`, name: `Malngin`},
  {code: `8512`, name: `Mudbera`},
  {code: `8512`, name: `Mudbura`},
  {code: `8512`, name: `Mudburra`},
  {code: `8512`, name: `Mudburra Djingli`},
  {code: `8512`, name: `Mudburra Garrawa`},
  {code: `8512`, name: `Mutpurra (Mudburra)`},
  {code: `8514`, name: `Ngardi`},
  {code: `8515`, name: `Airiman`},
  {code: `8515`, name: `Nariman`},
  {code: `8515`, name: `Narinman`},
  {code: `8515`, name: `Ngaiman`},
  {code: `8515`, name: `Ngainman`},
  {code: `8515`, name: `Ngainmun`},
  {code: `8515`, name: `Ngaringman`},
  {code: `8515`, name: `Ngarinman`},
  {code: `8515`, name: `Ngarinyman`},
  {code: `8515`, name: `Ngrainmun`},
  {code: `8516`, name: `Bililuna Dialect`},
  {code: `8516`, name: `Juwaliny`},
  {code: `8516`, name: `Walmadjari`},
  {code: `8516`, name: `Walmadyeri`},
  {code: `8516`, name: `Walmajari`},
  {code: `8516`, name: `Walmajarri`},
  {code: `8516`, name: `Walmajarri (Walmadjari)`},
  {code: `8516`, name: `Walmajeri`},
  {code: `8516`, name: `Walmatjari`},
  {code: `8516`, name: `Walmatjiri`},
  {code: `8516`, name: `Wanmadjari`},
  {code: `8516`, name: `Wulmatjerie`},
  {code: `8517`, name: `Wanyjirra`},
  {code: `8518`, name: `Walma`},
  {code: `8518`, name: `Warlmanpa`},
  {code: `8521`, name: `Alpiri`},
  {code: `8521`, name: `Wailbri`},
  {code: `8521`, name: `Walberri`},
  {code: `8521`, name: `Walbiri`},
  {code: `8521`, name: `Walbrai`},
  {code: `8521`, name: `Walbri`},
  {code: `8521`, name: `Waljpiri`},
  {code: `8521`, name: `Walpari`},
  {code: `8521`, name: `Walparri`},
  {code: `8521`, name: `Walpire`},
  {code: `8521`, name: `Walpiri`},
  {code: `8521`, name: `Walpiri Anmatjere`},
  {code: `8521`, name: `Walpiri Warramunga`},
  {code: `8521`, name: `Walpri`},
  {code: `8521`, name: `Walprie`},
  {code: `8521`, name: `Walpuri`},
  {code: `8521`, name: `Warlbiri`},
  {code: `8521`, name: `Warlpiri`},
  {code: `8521`, name: `Warlpiri Aranda`},
  {code: `8521`, name: `Warpiri`},
  {code: `8521`, name: `Warrabri`},
  {code: `8522`, name: `Waramunga`},
  {code: `8522`, name: `Waramungu`},
  {code: `8522`, name: `Warra Munga`},
  {code: `8522`, name: `Warramanga`},
  {code: `8522`, name: `Warramangu`},
  {code: `8522`, name: `Warramunga`},
  {code: `8522`, name: `Warranmunga`},
  {code: `8522`, name: `Warrumugu`},
  {code: `8522`, name: `Warrumungu`},
  {code: `8522`, name: `Warumungu`},
  {code: `8522`, name: `Wurrumungu`},
  {code: `8599`, name: `Northern Desert Fringe Area Languages, nec`},
  {code: `8600`, name: `Arandic, nfd`},
  {code: `8603`, name: `Alaywarra`},
  {code: `8603`, name: `Aljawara`},
  {code: `8603`, name: `Alwarre`},
  {code: `8603`, name: `Alyawara`},
  {code: `8603`, name: `Alyawarr`},
  {code: `8603`, name: `Alyawarr (Alyawarra)`},
  {code: `8603`, name: `Alyawarra`},
  {code: `8603`, name: `Alyawarre`},
  {code: `8603`, name: `Alyawarri`},
  {code: `8603`, name: `Alyuwara`},
  {code: `8603`, name: `Alywarr`},
  {code: `8603`, name: `Alywarra`},
  {code: `8603`, name: `Illaura`},
  {code: `8603`, name: `Illura`},
  {code: `8603`, name: `Yowera`},
  {code: `8606`, name: `Kadich`},
  {code: `8606`, name: `Kaidich`},
  {code: `8606`, name: `Kaidilt Bentinck`},
  {code: `8606`, name: `Kaitish`},
  {code: `8606`, name: `Kaititj`},
  {code: `8606`, name: `Kaititja`},
  {code: `8606`, name: `Katiji`},
  {code: `8606`, name: `Katitja`},
  {code: `8606`, name: `Katschi`},
  {code: `8606`, name: `Kaydish`},
  {code: `8606`, name: `Kaytej`},
  {code: `8606`, name: `Kaytetye`},
  {code: `8607`, name: `Antekerrepenh`},
  {code: `8610`, name: `Anmatyerr`},
  {code: `8610`, name: `Anmatyerr, nfd`},
  {code: `8611`, name: `Central Anmatyerr`},
  {code: `8612`, name: `Eastern Anmatyerr`},
  {code: `8619`, name: `Amajara`},
  {code: `8619`, name: `Amanantjere`},
  {code: `8619`, name: `Amatjira`},
  {code: `8619`, name: `Amatyere`},
  {code: `8619`, name: `Ami`},
  {code: `8619`, name: `Anmatjere Walpiri`},
  {code: `8619`, name: `Anmatjerra`},
  {code: `8619`, name: `Anmatjerre`},
  {code: `8619`, name: `Anmatjirra`},
  {code: `8619`, name: `Anmatyarra`},
  {code: `8619`, name: `Anmatyer`},
  {code: `8619`, name: `Anmatyere`},
  {code: `8619`, name: `Anmatyerr (Anmatyirra)`},
  {code: `8619`, name: `Anmatyerr, nec`},
  {code: `8620`, name: `Arrernte`},
  {code: `8620`, name: `Arrernte, nfd`},
  {code: `8621`, name: `Aranda (Eastern)`},
  {code: `8621`, name: `Arrente Eastern`},
  {code: `8621`, name: `East Arrente`},
  {code: `8621`, name: `Eastern Aranda`},
  {code: `8621`, name: `Eastern Arrada`},
  {code: `8621`, name: `Eastern Arrante`},
  {code: `8621`, name: `Eastern Arrente`},
  {code: `8621`, name: `Eastern Arrernte`},
  {code: `8621`, name: `Mparntwe Arrernte`},
  {code: `8622`, name: `Aranda (Western)`},
  {code: `8622`, name: `Aranda Lower`},
  {code: `8622`, name: `Aranda Southern`},
  {code: `8622`, name: `Arrent western`},
  {code: `8622`, name: `Pertame`},
  {code: `8622`, name: `Southern Arrernte`},
  {code: `8622`, name: `Western Aranda`},
  {code: `8622`, name: `Western Arrante`},
  {code: `8622`, name: `Western Arrarnta`},
  {code: `8622`, name: `Western Arrente`},
  {code: `8622`, name: `Western Arrernte`},
  {code: `8629`, name: `Aranda`},
  {code: `8629`, name: `Aranta`},
  {code: `8629`, name: `Ararnda`},
  {code: `8629`, name: `Arente`},
  {code: `8629`, name: `Arranda`},
  {code: `8629`, name: `Arranta`},
  {code: `8629`, name: `Arrarente`},
  {code: `8629`, name: `Arrent`},
  {code: `8629`, name: `Arrenta`},
  {code: `8629`, name: `Arrente`},
  {code: `8629`, name: `Arrerente`},
  {code: `8629`, name: `Arrernte`},
  {code: `8629`, name: `Arrernte (Aranda)`},
  {code: `8629`, name: `Arrernte, nec`},
  {code: `8629`, name: `Arrinda`},
  {code: `8629`, name: `Arrunta`},
  {code: `8629`, name: `Arunda`},
  {code: `8629`, name: `Arunta`},
  {code: `8629`, name: `Burringah`},
  {code: `8629`, name: `Pirdima`},
  {code: `8629`, name: `Pitjapitja`},
  {code: `8629`, name: `Wongkatjeri`},
  {code: `8699`, name: `Arandic, nec`},
  {code: `8700`, name: `Western Desert Languages, nfd`},
  {code: `8703`, name: `Antakarinya`},
  {code: `8703`, name: `Antikarinya`},
  {code: `8703`, name: `Antikirinya`},
  {code: `8704`, name: `Kartujarra`},
  {code: `8704`, name: `Katutjara`},
  {code: `8705`, name: `Kokatha`},
  {code: `8705`, name: `Kookatha`},
  {code: `8705`, name: `Kukatha`},
  {code: `8706`, name: `Gogodja`},
  {code: `8706`, name: `Gugaja`},
  {code: `8706`, name: `Kukadja`},
  {code: `8706`, name: `Kukaja`},
  {code: `8706`, name: `Kukata`},
  {code: `8706`, name: `Kukatja`},
  {code: `8706`, name: `Kukatja (Gugaja)`},
  {code: `8706`, name: `Kukutja`},
  {code: `8707`, name: `Pintupi Luritja`},
  {code: `8707`, name: `Aluridja`},
  {code: `8707`, name: `Loritja`},
  {code: `8707`, name: `Luraja`},
  {code: `8707`, name: `Luridji`},
  {code: `8707`, name: `Lurita`},
  {code: `8707`, name: `Luritcha`},
  {code: `8707`, name: `Luritga`},
  {code: `8707`, name: `Luritja`},
  {code: `8707`, name: `Luritja Arrente`},
  {code: `8707`, name: `Luritua`},
  {code: `8707`, name: `Lurritja`},
  {code: `8707`, name: `Western Loritja`},
  {code: `8708`, name: `Mandildjara`},
  {code: `8708`, name: `Mandjildjarra`},
  {code: `8708`, name: `Mandyildyarra`},
  {code: `8708`, name: `Mangarla`},
  {code: `8708`, name: `Mangu`},
  {code: `8708`, name: `Manjiljara`},
  {code: `8708`, name: `Manjiljarra`},
  {code: `8708`, name: `Mantjiltjarra`},
  {code: `8708`, name: `Manyjilyjarra`},
  {code: `8708`, name: `Marndjildjara`},
  {code: `8711`, name: `Maduwonga`},
  {code: `8711`, name: `Mardo`},
  {code: `8711`, name: `Mardu`},
  {code: `8711`, name: `Martu`},
  {code: `8711`, name: `Martu Wangka`},
  {code: `8711`, name: `Martuwanga`},
  {code: `8711`, name: `Martuwangka`},
  {code: `8711`, name: `Yindi`},
  {code: `8712`, name: `Maliar`},
  {code: `8712`, name: `Nalada`},
  {code: `8712`, name: `Ngaadjadjara`},
  {code: `8712`, name: `Ngaanyatjara`},
  {code: `8712`, name: `Ngaanyatjarra`},
  {code: `8712`, name: `Ngaatjatjara`},
  {code: `8712`, name: `Nganandjara`},
  {code: `8712`, name: `Ngannyatjarra`},
  {code: `8713`, name: `Pindu`},
  {code: `8713`, name: `Pintubi`},
  {code: `8713`, name: `Pintupi`},
  {code: `8713`, name: `Puntubu`},
  {code: `8714`, name: `Bidjandjara`},
  {code: `8714`, name: `Pijantjatjara`},
  {code: `8714`, name: `Mulatara`},
  {code: `8714`, name: `Pintjatjarra`},
  {code: `8714`, name: `Pit jan jat jarra`},
  {code: `8714`, name: `Pitanjtatjara`},
  {code: `8714`, name: `Pitantjatajara`},
  {code: `8714`, name: `Pitdjandjara`},
  {code: `8714`, name: `Pitimjara`},
  {code: `8714`, name: `Pitindjatjara`},
  {code: `8714`, name: `Pitjandara`},
  {code: `8714`, name: `Pitjandjadjara`},
  {code: `8714`, name: `Pitjanjajjarra`},
  {code: `8714`, name: `Pitjanjara`},
  {code: `8714`, name: `Pitjanjarra`},
  {code: `8714`, name: `Pitjant`},
  {code: `8714`, name: `Pitjantjajara`},
  {code: `8714`, name: `Pitjantjara`},
  {code: `8714`, name: `Pitjantjarra`},
  {code: `8714`, name: `Pitjantjartjara`},
  {code: `8714`, name: `Pitjantjatjara`},
  {code: `8714`, name: `Pitjantjatjaraa`},
  {code: `8714`, name: `Pitjantjtajara`},
  {code: `8714`, name: `Pitjantjtjara`},
  {code: `8714`, name: `Pitjanttatjara`},
  {code: `8714`, name: `Pitjara`},
  {code: `8714`, name: `Pitjatjara`},
  {code: `8714`, name: `Pitjatjatjara`},
  {code: `8714`, name: `Pitjijinarra`},
  {code: `8714`, name: `Pitjindjatjara`},
  {code: `8714`, name: `Pitjinjara`},
  {code: `8714`, name: `Pitjinjiara`},
  {code: `8714`, name: `Tjitiadjara`},
  {code: `8714`, name: `Wangajunka`},
  {code: `8715`, name: `Wangatjunga`},
  {code: `8715`, name: `Wangkajunga`},
  {code: `8715`, name: `Wangkatjunga`},
  {code: `8716`, name: `Bindinini`},
  {code: `8716`, name: `Pindini`},
  {code: `8716`, name: `Wangkatha`},
  {code: `8716`, name: `Wangkatja`},
  {code: `8716`, name: `Wankaija`},
  {code: `8716`, name: `Wankatja`},
  {code: `8716`, name: `Wongaii`},
  {code: `8716`, name: `Wongatha`},
  {code: `8716`, name: `Wonggai`},
  {code: `8716`, name: `Wonggaii`},
  {code: `8716`, name: `Wongi`},
  {code: `8716`, name: `Wongutha`},
  {code: `8717`, name: `Warnman`},
  {code: `8717`, name: `Warnmun`},
  {code: `8718`, name: `Anangu`},
  {code: `8718`, name: `Jankundjara`},
  {code: `8718`, name: `Kulpantja`},
  {code: `8718`, name: `Yangkuntjatjara`},
  {code: `8718`, name: `Yankunjara`},
  {code: `8718`, name: `Yankuntatjara`},
  {code: `8718`, name: `Yankuntjara`},
  {code: `8718`, name: `Yankuntjarra`},
  {code: `8718`, name: `Yankunytjatjara`},
  {code: `8718`, name: `Yankutjara`},
  {code: `8718`, name: `Yunkuntjatjara`},
  {code: `8721`, name: `Yulaparitya`},
  {code: `8721`, name: `Yulbarija`},
  {code: `8721`, name: `Yulparija`},
  {code: `8721`, name: `Yulparitja`},
  {code: `8722`, name: `Tjupany`},
  {code: `8799`, name: `Putijarra`},
  {code: `8799`, name: `Western Desert Languages, nec`},
  {code: `8800`, name: `Bad`},
  {code: `8800`, name: `Kimberley Area Languages, nfd`},
  {code: `8801`, name: `Badi`},
  {code: `8801`, name: `Bard`},
  {code: `8801`, name: `Barda`},
  {code: `8801`, name: `Bardi`},
  {code: `8801`, name: `Booneba`},
  {code: `8802`, name: `Bunaba`},
  {code: `8802`, name: `Bunuba`},
  {code: `8802`, name: `Bunuba (Bunaba)`},
  {code: `8802`, name: `Goonian`},
  {code: `8803`, name: `Goonien`},
  {code: `8803`, name: `Goonihandi`},
  {code: `8803`, name: `Gooniyandi`},
  {code: `8803`, name: `Goonyah`},
  {code: `8803`, name: `Gunan`},
  {code: `8803`, name: `Gunian`},
  {code: `8803`, name: `Konean`},
  {code: `8803`, name: `Konejandi`},
  {code: `8803`, name: `Kunan`},
  {code: `8803`, name: `Kunian`},
  {code: `8804`, name: `Mariyung`},
  {code: `8804`, name: `Miriwong`},
  {code: `8804`, name: `Miriwoong`},
  {code: `8804`, name: `Miriwung`},
  {code: `8804`, name: `Mirong`},
  {code: `8804`, name: `Mirrawong`},
  {code: `8804`, name: `Mirriwong`},
  {code: `8804`, name: `Mirriwoong`},
  {code: `8805`, name: `Ngarinyin`},
  {code: `8806`, name: `Nigena`},
  {code: `8806`, name: `Njikena`},
  {code: `8806`, name: `Nyigina`},
  {code: `8806`, name: `Nyikina`},
  {code: `8807`, name: `Worla`},
  {code: `8808`, name: `Worara`},
  {code: `8808`, name: `Worora`},
  {code: `8808`, name: `Wororra`},
  {code: `8808`, name: `Worrora`},
  {code: `8808`, name: `Worrorra`},
  {code: `8811`, name: `Kanar`},
  {code: `8811`, name: `Wunambal`},
  {code: `8812`, name: `Jawuru`},
  {code: `8812`, name: `Yawuru`},
  {code: `8813`, name: `Gambera`},
  {code: `8814`, name: `Jawi`},
  {code: `8815`, name: `Gidj`},
  {code: `8815`, name: `Gidja`},
  {code: `8815`, name: `Gidya`},
  {code: `8815`, name: `Gudgia`},
  {code: `8815`, name: `Kidja`},
  {code: `8815`, name: `kiga`},
  {code: `8815`, name: `Kija`},
  {code: `8815`, name: `Kitja`},
  {code: `8815`, name: `Lunga`},
  {code: `8815`, name: `Lungga`},
  {code: `8899`, name: `Andajin`},
  {code: `8899`, name: `Arawarri`},
  {code: `8899`, name: `Gajirrawoong`},
  {code: `8899`, name: `Kimberley Area Languages, nec`},
  {code: `8899`, name: `Kwini`},
  {code: `8899`, name: `Nyul Nyul`},
  {code: `8899`, name: `Warrwa`},
  {code: `8899`, name: `Yiiji`},
  {code: `8900`, name: `Other Australian Indigenous Languages, nfd`},
  {code: `8901`, name: `Adjnjamathanha`},
  {code: `8901`, name: `Adnamatana`},
  {code: `8901`, name: `Adnamathana`},
  {code: `8901`, name: `Adnjamathanha`},
  {code: `8901`, name: `Adnyamathana`},
  {code: `8901`, name: `Adnyamathanha`},
  {code: `8901`, name: `Adnyamathna`},
  {code: `8901`, name: `Adnymathana`},
  {code: `8901`, name: `Adnymathanha`},
  {code: `8901`, name: `Adnymathna`},
  {code: `8901`, name: `Adynamathana`},
  {code: `8901`, name: `Adynyamathanha`},
  {code: `8901`, name: `Anjimatana`},
  {code: `8901`, name: `Anjiwatana`},
  {code: `8901`, name: `Anyamathana`},
  {code: `8901`, name: `Unyamootha`},
  {code: `8902`, name: `Anabunna`},
  {code: `8902`, name: `Arabana`},
  {code: `8902`, name: `Arabuna`},
  {code: `8902`, name: `Arabunna`},
  {code: `8903`, name: `Bandjalang`},
  {code: `8903`, name: `Banjalang`},
  {code: `8903`, name: `Bundjalung`},
  {code: `8903`, name: `Bungalong`},
  {code: `8904`, name: `Bandjima`},
  {code: `8904`, name: `Banyjima`},
  {code: `8904`, name: `Bunjima`},
  {code: `8904`, name: `Panjima`},
  {code: `8905`, name: `Batjala`},
  {code: `8905`, name: `Butchulla`},
  {code: `8906`, name: `Bidjara`},
  {code: `8907`, name: `Camilaroi`},
  {code: `8907`, name: `Dhangati`},
  {code: `8907`, name: `Dhanggati`},
  {code: `8907`, name: `Dhanggatti`},
  {code: `8907`, name: `Dungutti`},
  {code: `8907`, name: `Gamilaroi`},
  {code: `8907`, name: `Kamalarai`},
  {code: `8908`, name: `Dieri`},
  {code: `8908`, name: `Diyari`},
  {code: `8911`, name: `Gamilaraay`},
  {code: `8911`, name: `Kamilaroi`},
  {code: `8913`, name: `Garuwali`},
  {code: `8913`, name: `Goore`},
  {code: `8914`, name: `Gidabal`},
  {code: `8914`, name: `Githabul`},
  {code: `8915`, name: `Gumbaynggir`},
  {code: `8915`, name: `Kumbainggar`},
  {code: `8916`, name: `Gurnai`},
  {code: `8916`, name: `Kanai`},
  {code: `8917`, name: `Karajarri`},
  {code: `8917`, name: `Karatjarri`},
  {code: `8917`, name: `Karrajarri`},
  {code: `8918`, name: `Kariarra`},
  {code: `8918`, name: `Kariyarra`},
  {code: `8921`, name: `Coorn`},
  {code: `8921`, name: `Coorna`},
  {code: `8921`, name: `Kaurna`},
  {code: `8921`, name: `Koornawarra`},
  {code: `8921`, name: `Warra`},
  {code: `8922`, name: `Kaiadilt`},
  {code: `8922`, name: `Kayardild`},
  {code: `8924`, name: `Aboriginal Kreol`},
  {code: `8924`, name: `Australia Kriol`},
  {code: `8924`, name: `Cherole`},
  {code: `8924`, name: `Creol Wan Jida`},
  {code: `8924`, name: `Creole Australian`},
  {code: `8924`, name: `Deemin`},
  {code: `8924`, name: `Kearol`},
  {code: `8924`, name: `Kreol`},
  {code: `8924`, name: `Kriol`},
  {code: `8924`, name: `Kriole`},
  {code: `8924`, name: `Kroil`},
  {code: `8924`, name: `Top End Kriol`},
  {code: `8925`, name: `Lardil`},
  {code: `8926`, name: `Managala`},
  {code: `8926`, name: `Mangala`},
  {code: `8926`, name: `Mangula`},
  {code: `8927`, name: `Marawari`},
  {code: `8927`, name: `Muruwari`},
  {code: `8928`, name: `Naranga`},
  {code: `8928`, name: `Narangga`},
  {code: `8928`, name: `Narranga`},
  {code: `8928`, name: `Narranggu`},
  {code: `8928`, name: `Narrunga`},
  {code: `8928`, name: `Narungga`},
  {code: `8931`, name: `Ngarluma`},
  {code: `8932`, name: `Nagrrindjeri`},
  {code: `8932`, name: `Narrinyari`},
  {code: `8932`, name: `Narrinyeri`},
  {code: `8932`, name: `Ngarinyeri`},
  {code: `8932`, name: `Ngarranjeri`},
  {code: `8932`, name: `Ngarrindejeri`},
  {code: `8932`, name: `Ngarrindjeri`},
  {code: `8932`, name: `Yaralde Tingar`},
  {code: `8932`, name: `Yaraldi`},
  {code: `8933`, name: `Nyamal`},
  {code: `8933`, name: `Nyamil`},
  {code: `8933`, name: `Nymal`},
  {code: `8934`, name: `Nangumarda`},
  {code: `8934`, name: `Nangumarta`},
  {code: `8934`, name: `Ngolibardu`},
  {code: `8934`, name: `Njangamarda`},
  {code: `8934`, name: `Nyangamada`},
  {code: `8934`, name: `Nyangumarda`},
  {code: `8934`, name: `Nyangumarta`},
  {code: `8934`, name: `Nyangumata`},
  {code: `8935`, name: `Ngoongar`},
  {code: `8935`, name: `Noogarr`},
  {code: `8935`, name: `Noongah`},
  {code: `8935`, name: `Noongar`},
  {code: `8935`, name: `Noongyar`},
  {code: `8935`, name: `Nygoonah`},
  {code: `8935`, name: `Nyoogar`},
  {code: `8935`, name: `Nyoonga`},
  {code: `8935`, name: `Nyoongar`},
  {code: `8935`, name: `Nyungah`},
  {code: `8935`, name: `Nyungar`},
  {code: `8935`, name: `Nyungar (Noongar)`},
  {code: `8936`, name: `Baagandji`},
  {code: `8936`, name: `Bagadji`},
  {code: `8936`, name: `Barkindji`},
  {code: `8936`, name: `Paakantji`},
  {code: `8936`, name: `Paakantyi`},
  {code: `8937`, name: `Palyku/Nyiyaparli`},
  {code: `8938`, name: `Wadgaree`},
  {code: `8938`, name: `Wadjari`},
  {code: `8938`, name: `Wadjeri`},
  {code: `8938`, name: `Waian`},
  {code: `8938`, name: `Wajari`},
  {code: `8938`, name: `Wajarri`},
  {code: `8938`, name: `Watjari`},
  {code: `8938`, name: `Wodjeri`},
  {code: `8938`, name: `Yamaji`},
  {code: `8938`, name: `Yamatji`},
  {code: `8938`, name: `Yamigi`},
  {code: `8941`, name: `Wiradhuri`},
  {code: `8941`, name: `Wiradjeri`},
  {code: `8941`, name: `Wiradjuri`},
  {code: `8941`, name: `Wiraduri`},
  {code: `8941`, name: `Wiradyuri`},
  {code: `8943`, name: `Indibandi`},
  {code: `8943`, name: `Indjibandi`},
  {code: `8943`, name: `Indjibandje`},
  {code: `8943`, name: `Ingibundy`},
  {code: `8943`, name: `Injabadi`},
  {code: `8943`, name: `Injabundi`},
  {code: `8943`, name: `Injibandi`},
  {code: `8943`, name: `Injibardi`},
  {code: `8943`, name: `Injibarndi`},
  {code: `8943`, name: `Injibund`},
  {code: `8943`, name: `Injibundie`},
  {code: `8943`, name: `Injie bundie`},
  {code: `8943`, name: `Injinbarndi`},
  {code: `8943`, name: `Jindjaparndi`},
  {code: `8943`, name: `Yanari`},
  {code: `8943`, name: `Yindjibarndi`},
  {code: `8943`, name: `Yingiebandie`},
  {code: `8944`, name: `Inawonga`},
  {code: `8944`, name: `Innawonga`},
  {code: `8944`, name: `Yinhawangka`},
  {code: `8945`, name: `Jotijota`},
  {code: `8945`, name: `Joti-Jota`},
  {code: `8945`, name: `Yodayod`},
  {code: `8945`, name: `Yoda-Yoda`},
  {code: `8945`, name: `Yoorta`},
  {code: `8945`, name: `Yota`},
  {code: `8945`, name: `Yoti Yoti`},
  {code: `8945`, name: `Yotta-yotta`},
  {code: `8945`, name: `Youta`},
  {code: `8945`, name: `Yorta Yorta`},
  {code: `8945`, name: `Yortayorta`},
  {code: `8946`, name: `Baanbay`},
  {code: `8946`, name: `Dandi`},
  {code: `8947`, name: `Badimaya`},
  {code: `8948`, name: `Barababaraba`},
  {code: `8951`, name: `Dadi Dadi`},
  {code: `8952`, name: `Dharawal`},
  {code: `8953`, name: `Djabwurrung`},
  {code: `8954`, name: `Gudjal`},
  {code: `8954`, name: `Gudjala`},
  {code: `8955`, name: `Keerray-Woorroong`},
  {code: `8956`, name: `Ladji Ladji`},
  {code: `8957`, name: `Mining`},
  {code: `8957`, name: `Mirning`},
  {code: `8957`, name: `Mirniny`},
  {code: `8958`, name: `Ngatjumaya`},
  {code: `8961`, name: `Waluwarra`},
  {code: `8962`, name: `Wangkangurru`},
  {code: `8963`, name: `Wargamay`},
  {code: `8964`, name: `Wergaia`},
  {code: `8965`, name: `Yugambeh`},
  {code: `8998`, name: `Aboriginal Australian`},
  {code: `8998`, name: `Aboriginal English, so described`},
  {code: `8999`, name: `Awabakal`},
  {code: `8999`, name: `Birpai`},
  {code: `8999`, name: `Birrbay`},
  {code: `8999`, name: `Darug`},
  {code: `8999`, name: `Darumbal`},
  {code: `8999`, name: `Dharug`},
  {code: `8999`, name: `Dharuk`},
  {code: `8999`, name: `Dharumbal`},
  {code: `8999`, name: `Dinggabal`},
  {code: `8999`, name: `Doonin`},
  {code: `8999`, name: `Dyrringan`},
  {code: `8999`, name: `Dyrringany`},
  {code: `8999`, name: `Gadhang`},
  {code: `8999`, name: `Galibal`},
  {code: `8999`, name: `Geawegal`},
  {code: `8999`, name: `Gurama`},
  {code: `8999`, name: `Guringay`},
  {code: `8999`, name: `Ingada`},
  {code: `8999`, name: `Jawara`},
  {code: `8999`, name: `Jeringin`},
  {code: `8999`, name: `Kattang`},
  {code: `8999`, name: `Kuringgai`},
  {code: `8999`, name: `Ku-Ring-Gai`},
  {code: `8999`, name: `Magadige`},
  {code: `8999`, name: `Magati Ke`},
  {code: `8999`, name: `Martuthunira`},
  {code: `8999`, name: `Minjunbal`},
  {code: `8999`, name: `Ngadyan`},
  {code: `8999`, name: `Ngandangara`},
  {code: `8999`, name: `Ngarla`},
  {code: `8999`, name: `Ngiyampaa`},
  {code: `8999`, name: `Nukunu`},
  {code: `8999`, name: `Other Australian Indigenous Languages, nec`},
  {code: `8999`, name: `Thalanyji`},
  {code: `8999`, name: `Tharrkari`},
  {code: `8999`, name: `Umbindhamu`},
  {code: `8999`, name: `Waka Waka`},
  {code: `8999`, name: `Wakaya`},
  {code: `8999`, name: `Wakka Wakka`},
  {code: `8999`, name: `Wamba Wamba`},
  {code: `8999`, name: `Wangkumara`},
  {code: `8999`, name: `Waradjuri`},
  {code: `8999`, name: `Warrimay`},
  {code: `8999`, name: `Widjabal`},
  {code: `8999`, name: `Wirangu`},
  {code: `8999`, name: `Woiwurrung`},
  {code: `8999`, name: `Woolah`},
  {code: `8999`, name: `Worimi`},
  {code: `8999`, name: `Yaegl`},
  {code: `8999`, name: `Yanama`},
  {code: `8999`, name: `Yinggarda`},
  {code: `8999`, name: `Yuin`},
  {code: `8999`, name: `Yuungay`},
  {code: `8999`, name: `Yuwaalaraay`},
  {code: `9000`, name: `Other Languages, nfd`},
  {code: `9000`, name: `P N G`},
  {code: `9000`, name: `Pacific`},
  {code: `9000`, name: `Papua New Guinean`},
  {code: `9101`, name: `American Indian`},
  {code: `9101`, name: `American Languages`},
  {code: `9101`, name: `Ebonics`},
  {code: `9101`, name: `Eskimo`},
  {code: `9101`, name: `Greenlandic`},
  {code: `9101`, name: `Guarani`},
  {code: `9101`, name: `Guyanese`},
  {code: `9101`, name: `Haitian`},
  {code: `9101`, name: `Jamaican`},
  {code: `9101`, name: `Lakota`},
  {code: `9101`, name: `Papiamento`},
  {code: `9101`, name: `Quechua`},
  {code: `9101`, name: `Tupi`},
  {code: `9200`, name: `African`},
  {code: `9200`, name: `African Creole`},
  {code: `9200`, name: `African Languages, nfd`},
  {code: `9200`, name: `Bantu`},
  {code: `9200`, name: `Creole African`},
  {code: `9200`, name: `Eritrean`},
  {code: `9200`, name: `Ethiopia`},
  {code: `9200`, name: `Ethiopian`},
  {code: `9200`, name: `Ghana`},
  {code: `9200`, name: `Ghanaian`},
  {code: `9200`, name: `Ghanian`},
  {code: `9200`, name: `Kenyan`},
  {code: `9200`, name: `Khoisan`},
  {code: `9200`, name: `Nigerian`},
  {code: `9200`, name: `Nilotic`},
  {code: `9200`, name: `Sudanese`},
  {code: `9200`, name: `West African`},
  {code: `9201`, name: `Acholi`},
  {code: `9201`, name: `Akoli`},
  {code: `9203`, name: `Akan`},
  {code: `9203`, name: `Akani`},
  {code: `9203`, name: `Asante`},
  {code: `9203`, name: `Ashanti`},
  {code: `9203`, name: `Ashanti Twi`},
  {code: `9203`, name: `Fante`},
  {code: `9203`, name: `Twi`},
  {code: `9203`, name: `Twi Akan`},
  {code: `9205`, name: `Creole Mauritian`},
  {code: `9205`, name: `Kreole`},
  {code: `9205`, name: `Maritian`},
  {code: `9205`, name: `Mauritian`},
  {code: `9205`, name: `Mauritian creole`},
  {code: `9205`, name: `Mauritius`},
  {code: `9206`, name: `Oromifa`},
  {code: `9206`, name: `Oromo`},
  {code: `9207`, name: `Mashona`},
  {code: `9207`, name: `Shona`},
  {code: `9208`, name: `Samalian`},
  {code: `9208`, name: `Somali`},
  {code: `9208`, name: `Somalia`},
  {code: `9208`, name: `Somalian`},
  {code: `9211`, name: `Kamba Swahilli`},
  {code: `9211`, name: `Kiswahili`},
  {code: `9211`, name: `Swahili`},
  {code: `9212`, name: `Yoruba`},
  {code: `9213`, name: `Zulu`},
  {code: `9214`, name: `Amarike`},
  {code: `9214`, name: `Ameherik`},
  {code: `9214`, name: `Amharic`},
  {code: `9214`, name: `Amhariec`},
  {code: `9214`, name: `Amhrice`},
  {code: `9215`, name: `Bemba`},
  {code: `9216`, name: `Dinka`},
  {code: `9217`, name: `Ewe`},
  {code: `9218`, name: `Ga`},
  {code: `9221`, name: `Harari`},
  {code: `9221`, name: `Hararian`},
  {code: `9222`, name: `Hausa`},
  {code: `9223`, name: `Ibo`},
  {code: `9223`, name: `Igbo`},
  {code: `9224`, name: `Kikuyu`},
  {code: `9225`, name: `Krio`},
  {code: `9226`, name: `Ganda`},
  {code: `9226`, name: `Luganda`},
  {code: `9226`, name: `Lugandian`},
  {code: `9227`, name: `Luo`},
  {code: `9227`, name: `Lwo`},
  {code: `9228`, name: `Ndebele`},
  {code: `9231`, name: `Nuer`},
  {code: `9232`, name: `Chewa`},
  {code: `9232`, name: `Chichewa`},
  {code: `9232`, name: `Nyang`},
  {code: `9232`, name: `Nyanja (Chichewa)`},
  {code: `9233`, name: `Shilluk`},
  {code: `9234`, name: `Tigre`},
  {code: `9235`, name: `Tigray`},
  {code: `9235`, name: `Tigrina`},
  {code: `9235`, name: `Tigringa`},
  {code: `9235`, name: `Tigrinya`},
  {code: `9235`, name: `Tigrnga`},
  {code: `9236`, name: `Botswanian`},
  {code: `9236`, name: `Setswana`},
  {code: `9236`, name: `Tswana`},
  {code: `9237`, name: `Xhosa`},
  {code: `9237`, name: `Xhosa Afrikaans`},
  {code: `9238`, name: `Creole Sechyelles`},
  {code: `9238`, name: `Seychelles Creole`},
  {code: `9241`, name: `Anuak`},
  {code: `9241`, name: `Anuak Arabic`},
  {code: `9242`, name: `Bari`},
  {code: `9242`, name: `Beri`},
  {code: `9242`, name: `Kakwa`},
  {code: `9242`, name: `Kuku`},
  {code: `9243`, name: `Bassa`},
  {code: `9243`, name: `Liberian Bassa`},
  {code: `9244`, name: `Dan`},
  {code: `9244`, name: `Dan (Gio-Dan)`},
  {code: `9244`, name: `Gio`},
  {code: `9244`, name: `Gio-Dan`},
  {code: `9245`, name: `Fulani`},
  {code: `9245`, name: `Fulfudle`},
  {code: `9245`, name: `Fulfulde`},
  {code: `9245`, name: `Fullah`},
  {code: `9245`, name: `Pulaar`},
  {code: `9245`, name: `Pular`},
  {code: `9246`, name: `Kinyarwanda`},
  {code: `9246`, name: `Kinyarwanda (Rwanda)`},
  {code: `9246`, name: `Kinyrwanda`},
  {code: `9246`, name: `Ruanda`},
  {code: `9246`, name: `Rwanda`},
  {code: `9247`, name: `Kirundi`},
  {code: `9247`, name: `Kirundi (Rundi)`},
  {code: `9247`, name: `Rundi`},
  {code: `9247`, name: `Urundi`},
  {code: `9248`, name: `Kpelle`},
  {code: `9251`, name: `Krahn`},
  {code: `9252`, name: `Liberian`},
  {code: `9252`, name: `Liberian (Liberian English)`},
  {code: `9252`, name: `Liberian English`},
  {code: `9252`, name: `Liberian Pidgin`},
  {code: `9252`, name: `Liberian Pidgin English`},
  {code: `9253`, name: `Loma`},
  {code: `9253`, name: `Loma (Lorma)`},
  {code: `9253`, name: `Lorma`},
  {code: `9254`, name: `Kuku Lumun`},
  {code: `9254`, name: `Lumun`},
  {code: `9254`, name: `Lumun (Kuku Lumun)`},
  {code: `9255`, name: `Madi`},
  {code: `9255`, name: `Ma'di`},
  {code: `9255`, name: `Ma'diti`},
  {code: `9256`, name: `Mandingo`},
  {code: `9256`, name: `Mandinka`},
  {code: `9257`, name: `Mann`},
  {code: `9257`, name: `Mano`},
  {code: `9258`, name: `Moro`},
  {code: `9258`, name: `Moro (Nuba Moro)`},
  {code: `9258`, name: `Nuba Moro`},
  {code: `9261`, name: `Temne`},
  {code: `9261`, name: `Themne`},
  {code: `9262`, name: `Lingala`},
  {code: `9262`, name: `Ngala`},
  {code: `9299`, name: `Soso`},
  {code: `9299`, name: `Susu`},
  {code: `9299`, name: `Vai`},
  {code: `9299`, name: `African Languages, nec`},
  {code: `9299`, name: `Ateso`},
  {code: `9299`, name: `Bambara`},
  {code: `9299`, name: `Berber`},
  {code: `9299`, name: `Bete`},
  {code: `9299`, name: `Bini`},
  {code: `9299`, name: `Bobangi`},
  {code: `9299`, name: `Bongli`},
  {code: `9299`, name: `Chokwe`},
  {code: `9299`, name: `Dabani`},
  {code: `9299`, name: `Dagbani`},
  {code: `9299`, name: `Duala`},
  {code: `9299`, name: `Dyula`},
  {code: `9299`, name: `Edo`},
  {code: `9299`, name: `Edo Ishan`},
  {code: `9299`, name: `Efik`},
  {code: `9299`, name: `Fang`},
  {code: `9299`, name: `Fur`},
  {code: `9299`, name: `Fuuta Jalon`},
  {code: `9299`, name: `Galla`},
  {code: `9299`, name: `Grebo`},
  {code: `9299`, name: `Guinean`},
  {code: `9299`, name: `Gurage`},
  {code: `9299`, name: `Gurma`},
  {code: `9299`, name: `Ijaw`},
  {code: `9299`, name: `Kanuri`},
  {code: `9299`, name: `Kikamba`},
  {code: `9299`, name: `Kikongo`},
  {code: `9299`, name: `kisi`},
  {code: `9299`, name: `Kisii`},
  {code: `9299`, name: `Kissi`},
  {code: `9299`, name: `Kongo`},
  {code: `9299`, name: `Kono`},
  {code: `9299`, name: `Kru`},
  {code: `9299`, name: `Kuranko`},
  {code: `9299`, name: `Lango`},
  {code: `9299`, name: `Luba`},
  {code: `9299`, name: `Luhya`},
  {code: `9299`, name: `Lunyankole`},
  {code: `9299`, name: `Malagasay`},
  {code: `9299`, name: `Malagasy`},
  {code: `9299`, name: `Malawian`},
  {code: `9299`, name: `Masai`},
  {code: `9299`, name: `Mende`},
  {code: `9299`, name: `More`},
  {code: `9299`, name: `Mossi`},
  {code: `9299`, name: `Nguni`},
  {code: `9299`, name: `Nubian`},
  {code: `9299`, name: `Nyasan`},
  {code: `9299`, name: `Sango`},
  {code: `9299`, name: `Senegalese`},
  {code: `9299`, name: `Serer`},
  {code: `9299`, name: `Sesothoian`},
  {code: `9299`, name: `Sierra Leone`},
  {code: `9299`, name: `Siswati`},
  {code: `9299`, name: `Songhai`},
  {code: `9299`, name: `Sotho`},
  {code: `9299`, name: `Swazi`},
  {code: `9299`, name: `Tani ewe`},
  {code: `9299`, name: `Tanzanian`},
  {code: `9299`, name: `Tiv`},
  {code: `9299`, name: `Tsonga`},
  {code: `9299`, name: `Ugandan`},
  {code: `9299`, name: `Uroba`},
  {code: `9299`, name: `Wolof`},
  {code: `9299`, name: `Zambian`},
  {code: `9299`, name: `Zande`},
  {code: `9299`, name: `Zimbabwean`},
  {code: `9300`, name: `Austronesian Oceanic`},
  {code: `9300`, name: `Melanesian`},
  {code: `9300`, name: `Micronesian`},
  {code: `9300`, name: `Pacific Austronesian Languages, nfd`},
  {code: `9300`, name: `Polynesian`},
  {code: `9301`, name: `Fiji`},
  {code: `9301`, name: `Fijian`},
  {code: `9302`, name: `Gilbertese`},
  {code: `9302`, name: `Ikiribati`},
  {code: `9302`, name: `Kiribatese`},
  {code: `9302`, name: `Kiribati`},
  {code: `9303`, name: `Cook Island`},
  {code: `9303`, name: `Cook Island Maori`},
  {code: `9303`, name: `Maori (Cook Island)`},
  {code: `9303`, name: `Maori Is`},
  {code: `9303`, name: `Native Cook Island`},
  {code: `9303`, name: `Rarotongan`},
  {code: `9304`, name: `Maori`},
  {code: `9304`, name: `Maori (New Zealand)`},
  {code: `9304`, name: `Maroi`},
  {code: `9304`, name: `Maurie`},
  {code: `9304`, name: `Moari`},
  {code: `9304`, name: `New Zealand Maori`},
  {code: `9304`, name: `Te Reo Maori`},
  {code: `9306`, name: `Nauruan`},
  {code: `9307`, name: `Niue`},
  {code: `9307`, name: `Niuean`},
  {code: `9308`, name: `Samoa`},
  {code: `9308`, name: `Samoan`},
  {code: `9308`, name: `Western Samoa`},
  {code: `9308`, name: `Western Samoan`},
  {code: `9311`, name: `Tonga`},
  {code: `9311`, name: `Tongan`},
  {code: `9312`, name: `Ratuman`},
  {code: `9312`, name: `Rotuman`},
  {code: `9313`, name: `Tokelau`},
  {code: `9313`, name: `Tokelauan`},
  {code: `9314`, name: `Tuvaluan`},
  {code: `9315`, name: `Yapese`},
  {code: `9399`, name: `Halia`},
  {code: `9399`, name: `Hawaiian`},
  {code: `9399`, name: `Kwarae`},
  {code: `9399`, name: `Lau`},
  {code: `9399`, name: `Marova`},
  {code: `9399`, name: `Marshallese`},
  {code: `9399`, name: `Ngunese`},
  {code: `9399`, name: `Pacific Austronesian Languages, nec`},
  {code: `9399`, name: `Palauan`},
  {code: `9399`, name: `Penryn`},
  {code: `9399`, name: `Puka Pukan`},
  {code: `9399`, name: `Roviania`},
  {code: `9399`, name: `Sikaiana`},
  {code: `9399`, name: `Tahitian`},
  {code: `9399`, name: `Togar`},
  {code: `9400`, name: `Oceanian Creole`},
  {code: `9400`, name: `Oceanian Pidgin`},
  {code: `9400`, name: `Oceanian Pidgins and Creoles, nfd`},
  {code: `9400`, name: `Oceanic Creole`},
  {code: `9400`, name: `Oceanic Pidgin`},
  {code: `9400`, name: `Pidgin Oceanian`},
  {code: `9402`, name: `Beach la Mar`},
  {code: `9402`, name: `Bichelamar`},
  {code: `9402`, name: `Bislama`},
  {code: `9403`, name: `Hawaiian English`},
  {code: `9404`, name: `Norf'k-Pitcairn`},
  {code: `9404`, name: `Norfolk English`},
  {code: `9404`, name: `Norfolk Island`},
  {code: `9404`, name: `Norfolkese`},
  {code: `9404`, name: `Pitcairnese`},
  {code: `9405`, name: `Pidgeon Solomon Island`},
  {code: `9405`, name: `Pidgin Solomon Island`},
  {code: `9405`, name: `Pigin Solomon Island`},
  {code: `9405`, name: `Solomon Island Pidgeon`},
  {code: `9405`, name: `Solomon Island Pidgin`},
  {code: `9405`, name: `Solomon Island Pigeon`},
  {code: `9405`, name: `Solomon Island Pigin`},
  {code: `9405`, name: `Solomon Islands Pidgeon`},
  {code: `9405`, name: `Solomon Islands Pidgin`},
  {code: `9405`, name: `Solomon Islands Pijin`},
  {code: `9499`, name: `New Caledonian French`},
  {code: `9499`, name: `Oceanian Pidgins and Creoles, nec`},
  {code: `9499`, name: `Pidgin French`},
  {code: `9499`, name: `Pijin`},
  {code: `9499`, name: `Queensland Canefield English`},
  {code: `9500`, name: `Papua New Guinea Languages, nfd`},
  {code: `9500`, name: `Papuan`},
  {code: `9500`, name: `PNG`},
  {code: `9502`, name: `Kiwai`},
  {code: `9503`, name: `Hiri Motu`},
  {code: `9503`, name: `Hiri-Motu`},
  {code: `9503`, name: `Motu`},
  {code: `9503`, name: `Motu (Hiri Motu)`},
  {code: `9504`, name: `English Pidgeon`},
  {code: `9504`, name: `English Pidgin`},
  {code: `9504`, name: `English Pigin`},
  {code: `9504`, name: `Neo Melanesian`},
  {code: `9504`, name: `Neomelanesian`},
  {code: `9504`, name: `Pidgeon English`},
  {code: `9504`, name: `Pidgin English`},
  {code: `9504`, name: `Pigeon English`},
  {code: `9504`, name: `PNG Pidgin`},
  {code: `9504`, name: `PNG Pisin`},
  {code: `9504`, name: `Tok Pisin`},
  {code: `9504`, name: `Tok Pisin (Neomelanesian)`},
  {code: `9599`, name: `Angal`},
  {code: `9599`, name: `Aroma`},
  {code: `9599`, name: `Bau`},
  {code: `9599`, name: `Chimbu`},
  {code: `9599`, name: `Dobu`},
  {code: `9599`, name: `Huli`},
  {code: `9599`, name: `Kate`},
  {code: `9599`, name: `Kuanua`},
  {code: `9599`, name: `Orokaiva`},
  {code: `9599`, name: `Papua New Guinea Languages, nec`},
  {code: `9599`, name: `Roro`},
  {code: `9599`, name: `Toaripi`},
  {code: `9599`, name: `Tolai`},
  {code: `9601`, name: `Esperanto`},
  {code: `9601`, name: `Interlingua`},
  {code: `9601`, name: `Invented Languages`},
  {code: `9601`, name: `Polari`},
  {code: `9700`, name: `Deaf language`},
  {code: `9700`, name: `Deaf sign`},
  {code: `9700`, name: `Sign`},
  {code: `9700`, name: `Sign for the deaf`},
  {code: `9700`, name: `Sign language`},
  {code: `9700`, name: `Sign Languages, nfd`},
  {code: `9700`, name: `Signe hearing`},
  {code: `9700`, name: `Signed English`},
  {code: `9700`, name: `Signing`},
  {code: `9701`, name: `Asl`},
  {code: `9701`, name: `Aulan`},
  {code: `9701`, name: `Auslan`},
  {code: `9701`, name: `Auslan sign language`},
  {code: `9701`, name: `Aussie sign language`},
  {code: `9701`, name: `Aust sign`},
  {code: `9701`, name: `Aust sign language`},
  {code: `9701`, name: `Austlan`},
  {code: `9701`, name: `Australian deaf sign`},
  {code: `9701`, name: `Australian sign`},
  {code: `9701`, name: `Australian sign language`},
  {code: `9701`, name: `Sign language Aust`},
  {code: `9701`, name: `Sign language Australian`},
  {code: `9702`, name: `Key Word Sign`},
  {code: `9702`, name: `Key Word Sign Australia`},
  {code: `9702`, name: `Key Word Sign Makaton`},
  {code: `9702`, name: `Makaton`},
  {code: `9702`, name: `Makaton sighning`},
  {code: `9702`, name: `Makaton sign`},
  {code: `9799`, name: `American sign language`},
  {code: `9799`, name: `Ameslan`},
  {code: `9799`, name: `English signed`},
  {code: `9799`, name: `Sign language American`},
  {code: `9799`, name: `Sign Languages, nec`},
]

// "Please enter a Language Code for Parent 2 Language other than English spoken at home. Common examples:
// 0002 = Not stated;
// 1201 =  English only;
// 2201 = Greek;
// 2303 = Spanish;
// 2401 = Italian;
// 4202 =  Arabic;
// 5203 = Hindi;
// 6302 =  Vietnamese;
// 6511 = Tagalog;
// 7101 =  Cantonese;
// 7104 = Mandarin.
//   For all languages see LanguageList tab which contains a copy of the Australian Standard Classification of Languages (ASCL) 2016."
const translateLanguageCode = (code: string) => {
  const codeStr = `${code || ''}`.trim().toUpperCase();
  if (codeStr === '') {
    return '0002';
  }
  if (codeStr === '7103') { // 7103 - Hokkien (Chinese) => 7107
    return '7107';
  }
  if (codeStr === '9202') { // 9202 - Afrikaans => 1403
    return '1403';
  }
  if (codeStr === 'GERM') { // GERM - German => 1301
    return '1301';
  }
  if (codeStr === '9800' || codeStr === 'ARM') { // 9800 - Armenian => 4901
    return '4901';
  }

  if (codeStr === '1201') {
    return '1201'
  }
  return code;
}

const validateLanguageCode = (code: string) => {
  const expectedCodes = _.uniq(acaraLanguages.map(item => `${item.code}`.trim()));
  return expectedCodes.indexOf(`${code}`.trim()) >= 0;
}


// "Please enter a number of 0, 5, 6, 7 or 8 for father/parent 2/guardian 2 highest level of non-school education completed:
// 7 = Bachelor degree or above;
// 6 = Advanced diploma/Diploma;
// 5 = Certificate I to IV (includes a trade certificate);
// 8 = No non-school education;
// 0 = Not Stated/Unknown."
const translateQualificationLevel = (qLevel: string) => {
  const qLevelStr = `${qLevel || ''}`.trim().toUpperCase();
  if (qLevelStr === '') {
    return '0';
  }

  if (['CERTCOM', 'LICE'].indexOf(qLevelStr) >= 0) {
    return '5';
  }

  if (['POSTGRAD', 'MA', 'DOC', 'UNDGRAD'].indexOf(qLevelStr) >= 0 ) {
    return '7';
  }
  return qLevelStr;
}

const validateQualificationLevel = (qLevel: string) => {
  const expectedCodes = ['7', '6', '5', '8', '0'];
  return expectedCodes.indexOf(`${qLevel}`.trim()) >= 0;
}

// "Please enter a number from 1, 2, 3, 4, 8 or 9 for father/parent 2/guardian 2 occupation group:
// 1 = Senior management in large business organisation, government administration and defence, and qualified professionals;
// 2 = Other business managers, arts/media/sportspersons and associate professionals;
// 3 = Tradespeople, clerks and skilled office, sales and service staff;
// 4 = Machine operators, hospitality staff, assistants, labourers and related workers;
// 8 = Not in paid work in last 12 months;
// 9 = Not stated or unknown. "
const translateOccupGroup = (oGrp: string) => {
  const oGrpStr = `${oGrp || ''}`.trim().toUpperCase();
  if (oGrpStr === '') {
    return '9';
  }
  return oGrpStr;
}

const validateOccupGroup = (oGrp: string) => {
  const expectedCodes = ['1', '2', '3', '4', '8', '9'];
  return expectedCodes.indexOf(`${oGrp}`.trim()) >= 0;
}


const parseParentInfo = (data: iAcaraData, parentIndex: number) => {
  // @ts-ignore
  const parentId = `${data[`parent${parentIndex}ID`] || ""}`.trim();
  if (parentId === "") {
    return null;
  }
  // @ts-ignore
  const parentName = data[`parent${parentIndex}Name`];
  // @ts-ignore
  const parentMainSLG = data[`parent${parentIndex}MainSLG`];
  // @ts-ignore
  const parentHomeLanguageCode = data[`parent${parentIndex}HomeLanguageCode`];
  // @ts-ignore
  const parentHomeLanguageDescription =  data[`parent${parentIndex}HomeLanguageDescription`];

  // @ts-ignore
  const parentHomeLanguageLangValidFlag = data[`parent${parentIndex}MainSLGValidFlag`];

  // @ts-ignore
  const parentHighestSchoolEducation = data[`parent${parentIndex}HighestSchoolEducation`];
  // @ts-ignore
  const parentHighestSchoolEducationCode = data[`parent${parentIndex}HighestSchoolEducationCode`];
  // @ts-ignore
  const parentHighestSchoolEducationValidFlag = data[`parent${parentIndex}HighestSchoolEducationValidFlag`];

  // @ts-ignore
  const parentHighestNonSchoolEducation = data[`parent${parentIndex}HighestNonSchoolEducation`];
  // @ts-ignore
  const parentHighestNonSchoolEducationValidFlag = data[`parent${parentIndex}HighestNonSchoolEducationValidFlag`];
  // @ts-ignore
  const parentHighestNonSchoolEducationCode = data[`parent${parentIndex}HighestNonSchoolEducationCode`];
  // @ts-ignore
  const parentHighestNonSchoolEducationDescription = data[`parent${parentIndex}HighestNonSchoolEducationDescription`];


  // @ts-ignore
  const parentOccupationGroup = data[`parent${parentIndex}OccupationGroup`];
  // @ts-ignore
  const parentOccupationGroupValidFlag = data[`parent${parentIndex}OccupationGroupValidFlag`];
  // @ts-ignore
  const parentOccupationGroupCode = data[`parent${parentIndex}OccupationGroupCode`];
  // @ts-ignore
  const parentOccupationGroupDescription = data[`parent${parentIndex}OccupationGroupDescription`];

  return {
    parentId,
    parentName,

    parentMainSLG,

    parentHomeLanguageCode,
    parentHomeLanguageDescription,
    parentHomeLanguageLangValidFlag,

    parentHighestSchoolEducation,
    parentHighestSchoolEducationCode,
    parentHighestSchoolEducationValidFlag,

    parentHighestNonSchoolEducation,
    parentHighestNonSchoolEducationValidFlag,
    parentHighestNonSchoolEducationCode,
    parentHighestNonSchoolEducationDescription,

    parentOccupationGroup,
    parentOccupationGroupValidFlag,
    parentOccupationGroupCode,
    parentOccupationGroupDescription,
  }
}

const AcaraDataHelper = {
  translateGender,
  translateATSIStatus,
  translateLanguageCode,
  validateLanguageCode,

  translateHighestSchoolEdu,
  validateHighestSchoolEdu,

  translateQualificationLevel,
  validateQualificationLevel,

  translateOccupGroup,
  validateOccupGroup,

  parseParentInfo,
};

export default AcaraDataHelper;
