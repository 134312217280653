export const STUDENT_REPORT_YEAR_STYLE_JNR_GRAPH = 'JUNIOR_GRH';
export const STUDENT_REPORT_YEAR_STYLE_DOCMAN_DOWNLOAD = 'DOCMAN_DOWNLOAD';
export const STUDENT_REPORT_YEAR_STYLE_COMPARATIVE_GRAPH = 'COMPARATIVE_GRAPH';

type iStudentReportStyle = {
  id: number;
  code: string;
  description: string;
};

export default iStudentReportStyle;
