import iBaseType from '../iBaseType';

export const MESSAGE_TYPE_CRON_JOBS_OPEROO_SAFETY_DOWNLOADER = 'CRON_JOBS_OPEROO_SAFETY_DOWNLOADER';
export const MESSAGE_TYPE_CRON_JOBS_JIRA_ASSET_DOWNLOADER = 'CRON_JOBS_JIRA_ASSET_DOWNLOADER';
export const MESSAGE_TYPE_MAIL_GUN_TEMPLATE = 'MAIL_GUN_TEMPLATE';
export const MESSAGE_TYPE_MAIL_GUN_EMAIL = 'MAIL_GUN_EMAIL';
export const MESSAGE_TYPE_JIRA_ASSET_MANAGEMENT_PICKUP = 'JIRA_ASSET_MANAGEMENT_PICKUP';
export const MESSAGE_TYPE_TIME_TABLE_IMPORT = 'TIME_TABLE_IMPORT';
export const MESSAGE_TYPE_FUNNEL_DOWNLOAD_LATEST = 'FUNNEL_DOWNLOAD_LATEST';
export const MESSAGE_TYPE_MESSAGE_CLEAN_UP = 'MESSAGE_CLEAN_UP';
export const MESSAGE_TYPE_TERM_ROLLING = 'TERM_ROLLING';
export const MESSAGE_TYPE_ABSENCE_SYNC_TO_SYNERGETIC = 'ABSENCE_SYNC_TO_SYNERGETIC';
export const MESSAGE_TYPE_FINANCE_EXPIRING_CREDIT_CARDS = 'FINANCE_EXPIRING_CREDIT_CARDS';
export const MESSAGE_TYPE_EXPIRING_PASSPORTS_OR_VISAS = 'EXPIRING_PASSPORTS_OR_VISAS';
export const MESSAGE_TYPE_SMTP_EMAIL = 'MESSAGE_TYPE_SMTP_EMAIL';
export const MESSAGE_TYPE_DONATION_RECEIPT_EMAIL = 'DONATION_RECEIPT_EMAIL';
export const MESSAGE_TYPE_HOY_CHAT_EMAIL = 'HOY_CHAT_EMAIL';

export const MESSAGE_STATUS_NEW = 'NEW';
export const MESSAGE_STATUS_WIP = 'PROCESSING';
export const MESSAGE_STATUS_SUCCESS = 'SUCCESS';
export const MESSAGE_STATUS_FAILED = 'FAILED';

type iMessage = iBaseType & {
  type: string;
  status?: string;
  request?: any;
  response?: any;
  error?: any;
  requestCheckSum?: string;
}

export default iMessage;
