import iSynLuCountry from '../Lookup/iSynLuCountry';

export const SYN_STUDENT_STATUS_ID_NORMAL = '0';
export const SYN_STUDENT_STATUS_ID_NEW = '1';
export const SYN_STUDENT_STATUS_ID_REPEATING = '2';
export const SYN_STUDENT_STATUS_ID_LEAVING = '5';
export const SYN_STUDENT_STATUS_ID_LEFT = '4';
export const SYN_STUDENT_STATUS_ID_FINALISED = 'F';
export const SYN_STUDENT_STATUS_PAST = 'PAST';

type iVStudent = {
  StudentID: number;
  StudentNameInternal: string;
  StudentNameExternal: string;
  StudentLegalFullName: string;
  StudentSurname: string;
  StudentTitle: string;
  StudentInitials: string;
  StudentSuffix: string;
  StudentPreferred: string;
  StudentPreferredFormal: string;
  StudentGiven1: string;
  StudentGiven2: string;
  StudentOccupEmail: string;
  StudentBirthDate: string;
  StudentGender: string;
  StudentMobilePhone: string;
  StudentDefaultMobilePhone: string;
  StudentOccupMobilePhone: string;
  StudentEmail: string;
  StudentEducation: string;
  StudentQualifications: string;
  FileYear: number;
  FileSemester: number;
  StudentActiveFlag: boolean;
  StudentStatus: string;
  StudentStatusDescription: string;
  StudentStatusSynergyMeaning: string;
  StudentStatusNextYear: string;
  StudentCampus: string;
  StudentCampusDescription: string;
  StudentCampusSort: number;
  StudentCampusRSBNumber: string;
  StudentYearLevel: number;
  SchoolingYearOffset: number;
  FundingYearLevel: number;
  StudentTypeCode: string;
  SecondaryTertiaryProgramme: string | null;
  StudentOverrideNextYearLevel: string | null;
  StudentOverrideNextStudentCampus: string;
  StudentOverrideNextBoarder: string;
  StudentYearLevelSort: number;
  StudentYearLevelDescription: string;
  StudentYearLevelCoordinator: string;
  StudentYearLevelTimeTableGroup: string;
  StudentYearLevelIncludeInCensusFlag: boolean;
  StudentHouse: string;
  StudentHouseDescription: string;
  StudentHeadOfHouse: string;
  StudentHouseCaptain: string;
  StudentForm: string;
  StudentFormDescription: string;
  StudentFormHomeRoom: string;
  StudentFormStaffName: string;
  StudentTutor: string;
  StudentTutorDescription: string;
  StudentTutorHomeRoom: string;
  StudentTutorName: string;
  StudentBoarder: string;
  StudentBoarderSynergyMeaning: string;
  StudentBoarderType: string;
  StudentBoardingHouse: string;
  StudentLockerBook: string;
  StudentLockerOther: string;
  StudentStudiesCode: string;
  StudentGovernmentStudentNumber: string;
  StudentEarlyLearningCode: string;
  VocationalEducationCode: string;
  StudentTertiaryCode: string;
  StudentTimeTableCode: string;
  StudentEntryDate: string;
  StudentEntryCampus: string;
  StudentEntryYearLevel: number;
  StudentLeavingDate: string | null;
  StudentLeavingReason: string;
  StudentLeavingDestination: string;
  StudentReturningDate: string | null;
  StudentEnquirySource: string;
  StudentEnquirySourceComment: string;
  StudentPreviousSchool: string;
  StudentTuitionVariationType: string;
  StudentDoctorName: string;
  StudentDoctorPhone: string;
  StudentDoctorPhoneAlt: string;
  StudentDoctorAddress: string;
  StudentDoctorSpecialisation: string;
  StudentDoctor2Name: string;
  StudentDoctor2Phone: string;
  StudentDoctor2PhoneAlt: string;
  StudentDoctor2Address: string;
  StudentDoctor2Specialisation: string;
  StudentDentistName: string;
  StudentDentistPhone: string;
  StudentDentistPhoneAlt: string;
  StudentDentistAddress: string;
  StudentEmergencyName: string;
  StudentEmergencyAddress: string;
  StudentEmergencyPhone: string;
  StudentEmergencyPhoneAlt: string;
  StudentEmergencyRelat: string;
  StudentEmergency2Name: string;
  StudentEmergency2Address: string;
  StudentEmergency2Phone: string;
  StudentEmergency2PhoneAlt: string;
  StudentEmergency2Relat: string;
  StudentEmergency3Name: string;
  StudentEmergency3Address: string;
  StudentEmergency3Phone: string;
  StudentEmergency3PhoneAlt: string;
  StudentEmergency3Relat: string;
  StudentLastLessonRcvdDate: string | null;
  StudentsVisaType: string;
  StudentVisaIssuedDate: string | null;
  StudentsVisaExpiryDate: string | null;
  StudentVisaNumber: string;
  StudentPassportCountryCode: string;
  StudentPassportIssuedDate: string | null;
  StudentPassportExpiryDate: string | null;
  StudentsPassportNo: string;
  StudentsComment: string | null;
  ExternalStudentFlag: boolean;
  EnglishSecondFlag: boolean;
  DisabilityFlag: boolean;
  IntegrationFlag: boolean;
  FullFeeFlag: boolean;
  EMAFlag: boolean;
  IntendsTertiaryFlag: boolean;
  CurrentSemesterOnlyFlag: boolean;
  StudentFamilyPosition: number;
  StudentParishCode: string;
  StudentParishDescription: string;
  StudentReligionCode: string;
  StudentReligionDescription: string;
  StudentPreviousSchoolCode: string;
  StudentPreviousSchoolDescription: string;
  StudentPreviousSchoolRSBNumber: string;
  StudentPreviousSchoolSuburb: string;
  IndigenousFlag: boolean;
  StudentIBFlag: boolean;
  StudentApplicationDate: string;
  StudentMedicalAlertFlag: boolean;
  StudentSpecialNeedsFlag: boolean;
  StudentStudyAtHomeFlag: boolean;
  StudentTSIFlag: boolean;
  StudentESLProficiencyLevel: string;
  StudentESLListeningLevelCode: string;
  StudentESLSpeakingLevelCode: string;
  StudentESLReadingLevelCode: string;
  StudentESLWritingLevelCode: string;
  LegalParentsSeparatedFlag: boolean;
  LegalParentsSeparatedReason: string;
  LegalCourtOrderType: string;
  LegalCourtOrderDate: string | null;
  LegalCourtOrderDetails: string;
  LegalPhotoWebFlag: boolean;
  LegalPhotoPromFlag: boolean;
  LegalPhotoPublicationFlag: boolean;
  LegalPrivacyPolicyAgreedFlag: boolean;
  StudentBarcode: string;
  StudentMedicalAlert: string;
  StudentSchoolFTE: boolean | null;
  StudentInitialSchoolingYear: string | null;
  StudentInitialSchoolingDate: string | null;
  StudentNonResidentFlag: boolean;
  DirectoryIncludeFlag: boolean;
  DirectoryProvideFlag: boolean;
  AddressID: number | null;
  StudentBirthCertificateOnFileFlag: boolean;
  StudentCountryOfBirthCode: string;
  StudentCountryOfBirthCodeRaw: string;
  StudentCountryOfBirthDescription: string;
  StudentHomeLanguageCode: string;
  StudentHomeLanguageDescription: string;
  ActualSemester: boolean;
  StudentAltCode: string;
  ImportantCommentsFlag: boolean;
  StudentLeavingDestinationDesc: string;
  ID: number;
  DebtorID: number | null;
  MCEETYAFormReceivedDate: string | null;
  StudentNationalityCode: string;
  StudentNationalityDescription: string;
  StudentNationality2Code: string;
  StudentNationality2Description: string;
  StudentIntendedTertiaryCourseCode: string;
  StudentSubSchool: string | null;
  AustudyFlag: boolean;
  StudentEmergencyRelatDesc: string;
  StudentEmergency2RelatDesc: string;
  StudentEmergency3RelatDesc: string;
  AllowAutoTuitionFlag: boolean;
  SpecialBillingType: string;
  TuitionNoSibFlag: boolean;
  LearningPathway: string;
  GenderSynergyMeaning: string;
  profileUrl: string;
  StudentPassportCountry?: iSynLuCountry;
  isFuture?: boolean;
};

export type iVPastStudent = iVStudent;
export type iVPastAndCurrentStudent = iVStudent & {
  StudentIsPastFlag: boolean;
}

export default iVStudent;
