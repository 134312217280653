import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import SchoolBoxLayout from './layouts/SchoolBoxLayout';
import PageNotFound from './components/PageNotFound';
import {Provider, useDispatch, useSelector} from 'react-redux';
import store, {RootState} from './redux/makeReduxStore';
import AppWrapper from './AppWrapper';
import './App.css';
import AssetPickupPage from './pages/assets/AssetPickupPage';
import {setIsProd} from './redux/reduxers/app.slice';
import PingService from './services/PingService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AlumniRegistrationPage from './pages/alumni/AlumniRegistrationPage';
import {
  URL_ALUMNI_REGISTRATION,
  URL_ASSET_PICK_UP,
  URL_CAMPUS_DISPLAY_PAGE, URL_CAMPUS_DISPLAY_SLIDE_SHOW_BY_LOCATION_PAGE, URL_ENEWS_VIEW_PAGE,
  URL_ONLINE_DONATION
} from './Url';
import OnlineDonationPage from './pages/OnlineDonation/OnlineDonationPage';
import PageNotFoundWithTechSupport from './components/PageNotFoundWithTechSupport';
import CampusDisplayPage from './pages/CampusDisplay/CampusDisplayPage';
import CampusDisplayByLocationIdPage from './pages/CampusDisplay/CampusDisplayByLocationIdPage';
import ENewsViewingPage from './pages/ENews/ENewsViewingPage';

const Router = () => {
  const {isProd} = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();
  useEffect(() => {
    PingService.ping()
      .then(res => {
        dispatch(setIsProd({isProd: res.isProd === true, backendSchoolBoxUrl: res.schoolBoxUrl }));
      })
      .catch(() => {
        dispatch(setIsProd({isProd: false, backendSchoolBoxUrl: undefined}));
      })
  }, [dispatch])
  return (
    <AppWrapper className={isProd !== true ? 'test-app' : ''}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PageNotFoundWithTechSupport />} />
          <Route path={URL_ASSET_PICK_UP} element={<AssetPickupPage />} />

          <Route path={URL_CAMPUS_DISPLAY_PAGE} element={<CampusDisplayPage />} />
          <Route path={URL_CAMPUS_DISPLAY_SLIDE_SHOW_BY_LOCATION_PAGE} element={<CampusDisplayByLocationIdPage />} />


          <Route path={URL_ALUMNI_REGISTRATION} element={<AlumniRegistrationPage />} />
          <Route path={URL_ONLINE_DONATION} element={<OnlineDonationPage />} />

          <Route path={URL_ENEWS_VIEW_PAGE} element={<ENewsViewingPage />} />


          <Route path="/modules/remote/:code" element={<SchoolBoxLayout />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AppWrapper>
  )
}

const App = () => {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}

export default App;
