import React from "react";
import styled from "styled-components";
import {
  iRecordType,
  iStudentAbsence
} from "../../../types/StudentAbsence/iStudentAbsence";
import { useEffect, useState } from "react";
import iPaginatedResult from "../../../types/iPaginatedResult";
import StudentAbsenceService from "../../../services/StudentAbsences/StudentAbsenceService";
import Toaster, {TOAST_TYPE_SUCCESS} from "../../../services/Toaster";
import PageLoadingSpinner from "../../../components/common/PageLoadingSpinner";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import moment from "moment-timezone";
import * as Icons from "react-bootstrap-icons";
import DeleteConfirmPopupBtn from "../../../components/common/DeleteConfirm/DeleteConfirmPopupBtn";
import MathHelper from "../../../helper/MathHelper";
import StudentAbsenceEditPopupBtn from "./StudentAbsenceEditPopupBtn";
import { FlexContainer } from "../../../styles";
import { OP_NOT } from "../../../helper/ServiceHelper";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/makeReduxStore";

const Wrapper = styled.div`
  .table-responsive {
    min-height: 300px;
  }
  .student-img {
    width: 60px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .dropdown {
    > button {
      padding: 0px;
    }
  }

  .record-table {
    td {
      padding-top: 4px;
      padding-bottom: 4px;
    }
    tr.row-mid {
      td {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }

    tr:not(.row-bottom) {
      td:not(.student-img),
      td:not(.created),
      td:not(.btns),
      td:not(.approved) {
        border-bottom: 0px;
      }
    }

    .form {
      width: 50px;
    }

    .has-note {
      width: 100px;
      font-size: 18px;
    }

    .student {
      .btn {
        padding: 0px;
        font-size: 12px;
      }
    }

    .reason {
      width: 260px;
    }

    .created,
    .approved {
      width: 160px;
      font-size: 12px;
    }

    .check-box {
      width: 23px;
      svg {
        cursor: pointer;
      }
    }
  }
`;

type iStudentAbsenceListPanel = {
  type: iRecordType;
};
const UnSyncdStudentAbsenceListPanel = ({ type }: iStudentAbsenceListPanel) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [selectedAbsences, setSelectedAbsences] = useState<iStudentAbsence[]>(
    []
  );
  const [records, setRecords] = useState<iPaginatedResult<iStudentAbsence>>();
  const [isLoading, setIsLoading] = useState(false);
  const [queuedIds, setQueuedIds] = useState<number[]>([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    setSelectedAbsences([]);
    StudentAbsenceService.getAll({
      where: JSON.stringify({
        type: type,
        active: true,
        syncd_at: null,
        syncd_by_id: null,
        syncd_AbsenceEventSeq: null,
        ...(queuedIds.length > 0 ? { id: { [OP_NOT]: queuedIds } } : {})
      }),
      include: `Student,AbsenceReason,CreatedBy,ApprovedBy,Expected,SyncdBy`,
      sort: "id:DESC"
    })
      .then(resp => {
        if (isCanceled) return;
        setRecords(resp);
      })
      .catch(err => {
        if (isCanceled) return;
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });

    return () => {
      isCanceled = true;
    };
  }, [type, count, queuedIds]);

  const getExpectedAndActualTime = (record: iStudentAbsence) => {
    if (record.Expected) {
      return (
        <FlexContainer className={"with-gap lg-gap"}>
          <small className={"text-muted"}>
            Expected: {moment(record.Expected.EventDate).format("lll")}
          </small>
          <small>
            <b>Actual:</b> {moment(record.EventDate).format("lll")}
          </small>
        </FlexContainer>
      );
    }
    if (record.isExpectedEvent === true) {
      return (
        <small className={"text-muted"}>
          Expected: {moment(record.EventDate).format("lll")}
        </small>
      );
    }
    return (
      <small>
        <b>Actual:</b> {moment(record.EventDate).format("lll")}
      </small>
    );
  };

  const handleSelectingRecordIds = (
    absences: iStudentAbsence[],
    checked: boolean
  ) => {
    const selectedIds = absences.map(record => record.id);
    if (checked === true) {
      return setSelectedAbsences(
        _.uniqBy([...selectedAbsences, ...absences], record => record.id)
      );
    }
    return setSelectedAbsences(
      _.uniqBy(
        selectedAbsences.filter(record => selectedIds.indexOf(record.id) < 0),
        record => record.id
      )
    );
  };

  const getBulkOptions = () => {
    if (selectedAbsences.length <= 0) {
      return null;
    }
    // console.log('selectedAbsences', selectedAbsences);
    const notApprovedAbsences = selectedAbsences.filter(
      record => `${record?.approved_at || ""}`.trim() === ""
    );
    const notSyncdAbsences = selectedAbsences.filter(
      record => `${record?.syncd_at || ""}`.trim() === ""
    );
    return (
      <DropdownButton title={`Actions`} size={"sm"} variant={"link"}>
        {notApprovedAbsences.length > 0 && (
          <Dropdown.Item>
            <DeleteConfirmPopupBtn
              deletingFn={() =>
                Promise.all(
                  notApprovedAbsences.map(record =>
                    StudentAbsenceService.update(record?.id || "", {
                      type: type,
                      approved_at: moment().toISOString(),
                      approved_by_id: user?.synergyId
                    })
                  )
                )
              }
              confirmString={`${user?.synergyId || "delete"}`}
              variant={"success"}
              confirmBtnString={`Approve ${notApprovedAbsences.length} record(s)`}
              confirmBtnVariant={'success'}
              size={"sm"}
              description={
                <h5 className={"text-success"}>
                  You are about to approve {notApprovedAbsences.length} selected
                  records. Are you sure to continue?
                </h5>
              }
              deletedCallbackFn={() => setCount(MathHelper.add(count, 1))}
            >
              <Icons.CheckLg /> Approve {notApprovedAbsences.length} record(s)
            </DeleteConfirmPopupBtn>
          </Dropdown.Item>
        )}
        {notSyncdAbsences.length > 0 && (
          <Dropdown.Item>
            <DeleteConfirmPopupBtn
              deletingFn={() =>
                Promise.all(
                  notSyncdAbsences.map(record =>
                    StudentAbsenceService.syncToSynergetic(record.id, { type })
                  )
                ).then(() => {
                  Toaster.showToast(
                    `Sync request successfully.`,
                    TOAST_TYPE_SUCCESS
                  )
                  setQueuedIds([...queuedIds, ...notSyncdAbsences.map(record => record.id)]);
                })
              }
              confirmString={`${user?.synergyId || "delete"}`}
              variant={"warning"}
              size={"sm"}
              confirmBtnString={`Sync ${notSyncdAbsences.length} record(s)`}
              confirmBtnVariant={'warning'}
              description={
                <h5 className={"text-warning"}>
                  You are about to synchronise {notSyncdAbsences.length} selected
                  records to Synergetic. Are you sure to continue?
                </h5>
              }
              deletedCallbackFn={() => setCount(MathHelper.add(count, 1))}
            >
              <Icons.CheckLg /> Sync {notSyncdAbsences.length} record(s)
            </DeleteConfirmPopupBtn>
          </Dropdown.Item>
        )}
        <Dropdown.Divider />
        <Dropdown.Item>
          <DeleteConfirmPopupBtn
            deletingFn={() =>
              Promise.all(
                selectedAbsences.map(record =>
                  StudentAbsenceService.remove(record.id, { type })
                )
              )
            }
            confirmString={`${user?.synergyId || "delete"}`}
            variant={"danger"}
            size={"sm"}
            description={
              <p className={"text-danger"}>
                You are about to delete {selectedAbsences.length} selected
                records. Are you sure to continue?
              </p>
            }
            deletedCallbackFn={() => setCount(MathHelper.add(count, 1))}
          >
            <Icons.Trash /> Delete {selectedAbsences.length} record(s)
          </DeleteConfirmPopupBtn>
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  if (isLoading) {
    return <PageLoadingSpinner />;
  }

  const recordIds = (records?.data || []).map(record => record.id);
  return (
    <Wrapper>
      <Table className={"record-table"} responsive>
        <thead>
          <tr>
            <th className={"check-box"}>
              {recordIds.length <= 0 ? null : selectedAbsences.length !==
                  recordIds.length || recordIds.length <= 0 ? (
                <Icons.Square
                  onClick={() =>
                    handleSelectingRecordIds(records?.data || [], true)
                  }
                />
              ) : (
                <Icons.CheckSquareFill
                  className={"text-primary"}
                  onClick={() =>
                    handleSelectingRecordIds(records?.data || [], false)
                  }
                />
              )}
            </th>
            <th colSpan={2}>{records?.total || 0} Student(s)</th>
            <th>Form</th>
            <th>Parent Slip?</th>
            <th>Date</th>
            <th>Reason</th>
            <th>Created</th>
            <th>Approved</th>
            <th className={"text-right"}>{getBulkOptions()}</th>
          </tr>
        </thead>
        <tbody>
          {(records?.data || [])
            .filter(record => queuedIds.indexOf(record.id) < 0)
            .map(record => {
              return (
                <React.Fragment key={record.id}>
                  <tr>
                    <td className={"check-box"} rowSpan={3}>
                      {_.findIndex(
                        selectedAbsences,
                        absence => record.id === absence.id
                      ) < 0 ? (
                        <Icons.Square
                          onClick={() =>
                            handleSelectingRecordIds([record], true)
                          }
                        />
                      ) : (
                        <Icons.CheckSquareFill
                          className={"text-primary"}
                          onClick={() =>
                            handleSelectingRecordIds([record], false)
                          }
                        />
                      )}
                    </td>
                    <td className={"student-img"} rowSpan={3}>
                      {`${record.Student?.profileUrl || ""}`.trim() !== "" ? (
                        <img
                          src={`${record.Student?.profileUrl || ""}`.trim()}
                          alt={"student profile"}
                        />
                      ) : null}
                    </td>
                    <td className={"student"}>
                      <StudentAbsenceEditPopupBtn
                        recordType={type}
                        studentAbsenceRecord={record}
                        variant={"link"}
                        size={"sm"}
                        onSaved={(savedRecord, jobQueued) => {
                          if (jobQueued === true && savedRecord) {
                            setQueuedIds([...queuedIds, savedRecord?.id]);
                            // return;
                          }
                          setCount(MathHelper.add(count, 1));
                        }}
                      >
                        {record.Student?.StudentNameInternal}
                        {`${record.StudentID || ""}`.trim() !== ""
                          ? ` [${`${record.StudentID || ""}`.trim()}]`
                          : ""}
                      </StudentAbsenceEditPopupBtn>
                    </td>
                    <td className={"form"}>
                      <div>{record.Student?.StudentForm}</div>
                    </td>
                    <td className={"has-note"}>
                      <div>
                        {record.hasNote ? (
                          <span
                            className={"text-success"}
                            title={"parent knows"}
                          >
                            <Icons.CheckSquareFill />
                          </span>
                        ) : null}
                      </div>
                    </td>
                    <td className={"date"}>
                      <div>
                        {moment(record.EventDate).format("DD/MMM/YYYY")}
                      </div>
                    </td>
                    <td className={"reason"} rowSpan={2}>
                      <div>
                        <b>
                          {record.AbsenceCode} -{" "}
                          {record.AbsenceReason?.Description}
                        </b>
                      </div>
                    </td>
                    <td className={"created"} rowSpan={3}>
                      <div>
                        <b>By:</b> {record.CreatedBy?.Surname},{" "}
                        {record.CreatedBy?.Given1}
                      </div>
                      <div>
                        <b>@:</b>
                        {moment(record.created_at).format("lll")}
                      </div>
                    </td>
                    <td className={"approved"} rowSpan={3}>
                      {record.ApprovedBy ? (
                        <div>
                          <b>By:</b> {record.ApprovedBy?.Surname},{" "}
                          {record.ApprovedBy?.Given1}
                        </div>
                      ) : null}
                      {`${record.approved_at || ""}`.trim() === "" ? null : (
                        <div>
                          <b>@:</b>
                          {moment(record.approved_at).format("lll")}
                        </div>
                      )}
                    </td>
                    <td className={"btns text-right"} rowSpan={3}>
                      <DeleteConfirmPopupBtn
                        deletingFn={() =>
                          StudentAbsenceService.remove(record.id, { type })
                        }
                        confirmString={`${record.id}`}
                        variant={"danger"}
                        size={"sm"}
                        deletedCallbackFn={() =>
                          setCount(MathHelper.add(count, 1))
                        }
                      >
                        <Icons.Trash />
                      </DeleteConfirmPopupBtn>
                    </td>
                  </tr>
                  <tr className={"row-mid"}>
                    <td colSpan={5}>{getExpectedAndActualTime(record)}</td>
                  </tr>
                  <tr className={"row-bottom"}>
                    <td colSpan={8}>
                      <small className={"text-muted"}>
                        Comments: {record.Comments}
                      </small>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default UnSyncdStudentAbsenceListPanel;
