export const FUTURE_STUDENT_STATUS_FINALISED = 'F';

type iSynVFutureStudent = {
  FutureID: number;
  FutureName: string;
  FutureNameInternal: string;
  FutureNameExternal: string;
  FutureLegalFullName: string;
  FutureSurname: string;
  FutureTitle: string;
  FutureInitials: string;
  FutureSuffix: string;
  FuturePreferred: string;
  FuturePreferredFormal: string;
  FutureGiven1: string;
  FutureGiven2: string;
  FutureOccupEmail: string;
  FutureBirthDate: Date | string | null;
  FutureGender: string;
  FutureMobilePhone: string;
  FutureDefaultMobilePhone: string;
  FutureEmail: string;
  ReligionCode: string;
  ReligionDescription: string;
  FutureEnquiryDate: Date | null;
  FutureApplicationDate: Date;
  FutureEnrolDate: Date;
  FutureEnrolYear: number;
  FutureEnrolMonth: number;
  FutureCampus: string;
  FutureBoarder: string;
  FutureBoarderType: string;
  FutureBoardingHouse: string;
  FutureYearLevel: number;
  FutureSuppEnrolDate1: Date | null;
  FutureSuppCampus1: string;
  FutureSuppYearLevel1: string | null;
  FutureSuppEnrolDate2: Date | null;
  FutureSuppCampus2: string;
  FutureSuppYearLevel2: string | null;
  FutureStatus: string;
  FutureStatusChangeDate: Date;
  FutureStatusChangeDescription: string;
  FutureStatusDescription: string;
  FutureStatusSynergyMeaning: string;
  FuturePriority: string;
  FuturePriorityDescription: string;
  FutureHouse: string;
  FutureHouseDescription: string;
  FutureHeadOfHouse: string;
  FutureHouseCaptain: string;
  FutureForm: string;
  FutureTutor: string;
  CourseClassificationCode: string;
  FutureEnquirySource: string;
  FutureEnquirySourceComment: string;
  FuturePreviousSchoolCode: string;
  FuturePreviousSchoolDescription: string;
  FuturePreviousSchoolRSBNumber: string;
  FutureStudentParishCode: string;
  FutureStudentParishDescript: string;
  FutureMedicalAlert: string;
  FutureExternalStudentFlag: boolean;
  FutureEnglishSecondFlag: boolean;
  FutureDisabilityFlag: boolean;
  FutureFullFeeFlag: boolean;
  FutureTuitionVariationType: string;
  FutureTuitionNoSibFlag: boolean;
  FuturePhotoSubmittedFlag: boolean;
  FutureBirthCertificateFlag: boolean;
  FutureNonResidentFlag: boolean;
  FutureIBFlag: boolean;
  FutureIntendsTertiaryFlag: boolean;
  FutureImmunisedFlag: boolean;
  FutureCrecheFlag: boolean;
  FutureBeforeSchoolCareFlag: boolean;
  FutureAfterSchoolCareFlag: boolean;
  FutureVisaType: string;
  FutureVisaExpiryDate: Date | string | null;
  FutureVisaNumber: string;
  FutureVisaIssuedDate: Date | string | null;
  FuturePassportCountryCode: string;
  FuturePassportExpiryDate: Date | string | null;
  FuturePassportNo: string;
  FutureComment: string;
  FutureCommentWarningFlag: boolean;
  FutureBaptismFlag: boolean;
  FutureBaptismDate: Date | null;
  FutureReconciliationFlag: boolean;
  FutureReconciliationDate: Date | null;
  FutureCommunionFlag: boolean;
  FutureCommunionDate: Date | null;
  FutureConfirmationFlag: boolean;
  FutureConfirmationDate: Date | null;
  FutureAllowAutoChargesFlag: boolean;
  FutureAllowAutoTuitionFlag: boolean;
  FutureAustudyFlag: boolean;
  FutureBarcode: string;
  FutureIndigenousFlag: boolean;
  FutureNaturalisationDate: Date | null;
  FutureMedicalAlertFlag: boolean;
  FutureSpecialBillingType: string;
  FutureStudyAtHomeFlag: boolean;
  FutureTSIFlag: boolean;
  FutureSpecialNeedsFlag: boolean;
  FutureESLProficiencyLevel: string;
  FutureESLListeningLevelCode: string;
  FutureESLSpeakingLevelCode: string;
  FutureESLReadingLevelCode: string;
  FutureESLWritingLevelCode: string;
  FutureDoctorName: string;
  FutureDoctorPhone: string;
  FutureDoctorPhoneAlt: string;
  FutureDoctorAddress: string;
  FutureDoctorSpecialisation: string;
  FutureDoctor2Name: string;
  FutureDoctor2Phone: string;
  FutureDoctorPhone2Alt: string;
  FutureDoctor2Address: string;
  FutureDoctor2Specialisation: string;
  FutureDentistName: string;
  FutureDentistPhone: string;
  FutureDentistPhoneAlt: string;
  FutureDentistAddress: string;
  FutureEmergencyName: string;
  FutureEmergencyAddress: string;
  FutureEmergencyPhone: string;
  FutureEmergencyPhoneAlt: string;
  FutureEmergencyRelat: string;
  FutureEmergencyRelatDescription: string;
  FutureEmergency2Name: string;
  FutureEmergency2Address: string;
  FutureEmergency2Phone: string;
  FutureEmergency2PhoneAlt: string;
  FutureEmergency2Relat: string;
  FutureEmergency2RelatDescription: string;
  FutureEmergency3Name: string;
  FutureEmergency3Address: string;
  FutureEmergency3Phone: string;
  FutureEmergency3PhoneAlt: string;
  FutureEmergency3Relat: string;
  FutureEmergency3RelatDescription: string;
  LegalPhotoWebFlag: boolean;
  LegalPhotoPromFlag: boolean;
  LegalPhotoPublicationFlag: boolean;
  MCEETYAFormReceivedDate: Date | null;
  FutureNationalityCode: string;
  FutureNationality2Code: string;
  FutureSubSchool: string | null;
  IdamGuid: string | null;
  LearningPathway: string;
  GenderSynergyMeaning: string;
}

export default iSynVFutureStudent
